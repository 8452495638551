import {
  fullPriceBreakdownCents as _fullPriceBreakdownCents,
  overageTotal as _overageTotal
} from 'common/dist/price';
import { overagePriceCents } from 'common/dist/virtuals/Payment';
import { fbMinCents, applyDelta } from 'common/dist/virtuals/BookingRequest';
import moment from 'moment';

// import bookingRequestSchema from 'common/dist/schemas/BookingRequest';
import { each, cloneDeep, get, last, isFinite, set, some, keys, startsWith, find } from 'lodash';
import { Counteroffer } from 'spc/lib/database/types/booking-request';

/**
 * In contrast to the payment directive `costBreakdown`, this directive
 * displays the payment details for a bookingRequest. `costBreakdown` takes in
 * a payment from a request's payment schedule and shows a snapshot of the price
 * at that moment in time
 */

module.exports = function () {
  return {
    template: require('./request-payment-details-client.jade'),
    scope: {
      request: '=',
      guest: '=',
      client: '=',
      printFinalReceipt: '&',
      venueUrl: '<',
      balancePaymentMethod: '<',
      attachment: '<'
    },
    controller: ['$scope', '$counteroffer', 'ENUMS', '$interval', '$api', '$clickOk', 'photoDialog', 'spaceDetailModal', 'menuDetailModal', '$cloudinary', 'drinksDetailModal', 'paymentHelpers', 'unwrapError', '$window', 'seePaymentAttachmentsModal', 'RequestArrayFactory', 'allMenusModal', 'allSpacesModal', 'drinkPackagesModal', 'replaceCardModal', function ($scope, $counteroffer, ENUMS, $interval, $api, $clickOk, photoDialog, spaceDetailModal, menuDetailModal, $cloudinary,
      drinksDetailModal, paymentHelpers, unwrapError, $window, seePaymentAttachmentsModal, RequestArrayFactory, allMenusModal, allSpacesModal, drinkPackagesModal, replaceCardModal) {
      $scope.loading = true;
      const REQUEST_LISTENER_INTERVAL = 1000 * 10;
      $scope.openSpaceDetailModal = openSpaceDetailModal;
      $scope.openMenuDetailModal = openMenuDetailModal;
      $scope.openDrinksDetailModal = openDrinksDetailModal;
      $scope.isBalancePaymentFailed = isBalancePaymentFailed;
      $scope.openPhotoDialog = openPhotoDialog;
      $scope.openMenuPhotoDialog = openMenuPhotoDialog;
      $scope.openDrinkPackagesModal = openDrinkPackagesModal;
      $scope.fullPriceBreakdownCents = _fullPriceBreakdownCents;
      $scope.requestListener;
      $scope.hasTimeDelta = $counteroffer.hasTimeDelta;
      $scope.displayTime = $counteroffer.displayTime;
      $scope.isNew = isNew;
      $scope.isNewDrinkOrAddon = isNewDrinkOrAddon;
      $scope.now = () => moment();
      $scope.openSeePaymentAttachmentsModal = openSeePaymentAttachmentsModal;
      $scope.overageTotal = _overageTotal;
      $scope.overagePriceCents = overagePriceCents;
      $scope.hasFbMinCents = hasFbMinCents;
      $scope.fetchAndConvertUrl = $cloudinary.fetchAndConvertURL;
      $scope.allMenusModal = allMenusModal;
      $scope.allSpacesModal = allSpacesModal;
      $scope.replaceCardModal = replaceCardModal;
      $scope.openReplaceCardModal = openReplaceCardModal;
      $scope.getDate = getDate;
      init();

      ///// Listeners

      // Stop listening real-time for updates to the given request
      $scope.$on('STOP_REQUEST_LISTENER', function () {
        if ($scope.requestListener) {
          $interval.cancel($scope.requestListener);
        }
      });

      // Start listening for real-time updates to the given request
      $scope.$on('START_REQUEST_LISTENER', (ev, params) => {
        $scope.request = params.request || $scope.request;
        createClone();
        setRequestListener();
      });

      // destroy request listener on `$destroy` because angular `$destroy` events don't clean up `$interval`s
      $scope.$on('$destroy', function () {
        if ($scope.requestListener) {
          $interval.cancel($scope.requestListener);
        }
      });

      function init() {
        const requestWatcher = $scope.$watch('request', function (request) {
          if (!request) {
            return;
          }
          getWinelists();
          if (!isFinite(get(request, 'data.gratuityPercentage'))) {
            set(request, 'data.gratuityPercentage',
              get(request, 'venue.data.fees.gratuityPercentage'));
          }

          createClone();
          setRequestListener();
          requestWatcher();
          getSpaces();
          getVenueDrinkPackages();
          $scope.loading = false;
        });
      }

      function getDate(date) {
        const clone = cloneDeep($scope.clone);
        return moment(clone.data.date).local().format('ddd. MMM DD, YYYY');
      }

      function getSpaces() {
        return $api.Venues.Spaces.get($scope.clone.venue.slug)
          .then((res) => { $scope.spaces = res.data.data; })
          .catch(error => unwrapError(error));
      }
      function isBalancePaymentFailed(request) {
        const { payment } = request.data;
        const { skipBalance, schedule } = payment;
        const balancePayment = schedule.find(s => s.title === 'BALANCE');

        return (
          !skipBalance &&
          balancePayment &&
          balancePayment.state === 'ERRORED'
        );
      }

      function getWinelists() {
        return $api.Venues.getWinelists($scope.request.venue.slug)
          .then(res => $scope.venue = { data: { winelists: res.data.winelists } })
          .catch(error => unwrapError(error));
      }

      function openSpaceDetailModal(space) {
        spaceDetailModal(space);
      }

      function openMenuDetailModal(menu) {
        menuDetailModal({ data: menu });
      }

      function openDrinksDetailModal(drink) {
        drinksDetailModal({ data: drink });
      }

      function getVenueDrinkPackages() {
        $api.Drinks.getVenueDrinks($scope.clone.venue)
          .then((res) => {
            $scope.availibleDrinks = res.drinks;
          })
          .catch(error => unwrapError(error));
      }

      function openDrinkPackagesModal() {
        drinkPackagesModal($scope.availibleDrinks);
      }

      function openPhotoDialog(space, initialPhoto) {
        return photoDialog({ photos: space.data.photos, initialPhoto: space.data.photos[initialPhoto] });
      }

      function openMenuPhotoDialog() {
        return $scope.clone.data.menu.photos.length && photoDialog({ photos: $scope.clone.data.menu.photos, initialPhoto: $scope.clone.data.menu.photos[$scope.clone.data.menu.coverIndex] });
      }

      function setSelectedSpace() {
        $scope.clone.selectedSpace = $scope.clone.venue.data.spaces[0];
      }

      function setDeltas() {
        $scope.delta = {};

        const lastCounteroffer = last($scope.request.counteroffers) as Counteroffer;

        const theState = get(lastCounteroffer, 'state');

        const guestCanSeeDelta = theState === 'READY';
        if (guestCanSeeDelta) {
          applyDelta($scope.clone, lastCounteroffer.delta);
          each(lastCounteroffer.delta, function (delta) {
            $scope.delta[delta.path] = delta;
          });
          setSelectedSpace();
        }

      }

      function createClone() {
        $scope.clone = cloneDeep($scope.request);

        setDeltas();

        $scope.schedule = paymentHelpers.getDisplayableSchedule($scope.request);

        $scope.costBreakdownCents =
          _fullPriceBreakdownCents($scope.clone);

        // Get non-deleted elements from various subdoc arrays so that we dont have to do this every $digest cycle
        {
          $scope.fbAddOns = RequestArrayFactory.getFbAddOns($scope.clone);
          $scope.nonFbAddOns = RequestArrayFactory.getNonFbAddOns($scope.clone, true);
          $scope.visibleDrinks = RequestArrayFactory.filterNonDeleted($scope.clone.data.drinks);
          $scope.visibleAddOns = RequestArrayFactory.filterNonDeleted($scope.clone.data.addOns);
        }

        $scope.timeSpan = $counteroffer.displayTime($scope.clone, $scope.delta);
      }

      /* Counteroffer-related fns */

      function isNew(path: string): Boolean {
        if (!$scope.delta) {
          return;
        }

        return $counteroffer.isNew($scope.delta, path);
      }

      function isNewDrinkOrAddon(path) {
        if (!$scope.delta) {
          return;
        }

        const paths = keys($scope.delta);

        return some(paths, p => startsWith(p, path));
      }

      function openReplaceCardModal(request) {
        $scope.replaceCardModal(request)
          .then(res => res.value.method ? $scope.balancePaymentMethod = res.value.method : null)
          .catch(error => unwrapError(error));
      }


      function hasFbMinCents(request) {
        return isFinite(fbMinCents(request));
      }

      function openSeePaymentAttachmentsModal() {
        return seePaymentAttachmentsModal($scope.clone).
          catch(unwrapError);
      }

      function setRequestListener() {
        'On the inside';
        // cancel listener if it exists for some reason
        if ($scope.requestListener) {
          $interval.cancel($scope.requestListener);
        }

        if (!$scope.client) {
          return;
        }

        // set requestListener to the promise returned by $interval so we can call `$interval.cancel($scope.requestListener)` to cancel
        $scope.requestListener = $interval(function () {
          if (!$scope.request) {
            return;
          }

          /**
           * algo
           *
           * 1. check new counteroffers
           * 2. Is there no last counteroffer? If not, just return
           * 3. Are there any counteroffers in `$scope.request`?
           *    3a. If not, and there is one in the response, push it and apply.
           *    3b. Otherwise, compare the times of the last counteroffer in both arrays.
           *    3bi. Are they the same? If so, just return since there was no change.
           *    3bii. If the times are different, we need to replace the last counteroffer of `$scope.request` with
           *    the more recent version of that counteroffer and apply it.
           * 4. If there was a change, set state tracker that will trigger the modal
           */

          // 1
          $api.Requests.checkCounteroffer($scope.request._id).
            then(function (response) {
              const originalState = get($scope, 'request.state');

              const state = get(response, 'data.state');

              const newMessages = get(response, 'data.messages');
              const newAttachments = get(response, 'data.attachments');

              if (get(newMessages, 'length')) {
                $scope.$emit('UPDATE_MESSAGES', { messages: newMessages });
              }

              if (get(newAttachments, 'length')) {
                $scope.$emit('UPDATE_ATTACHMENTS', { attachments: newAttachments });
              }

              if (originalState !== state) {
                set($scope, 'request.state', state);
              }

              const lastCounterofferOfCurrent = last($scope.request.counteroffers);

              const lastCounterofferOfUpdate = get(response, 'data.counteroffer');

              if (!lastCounterofferOfUpdate) {
                return;
              }

              if (lastCounterofferOfUpdate.state === 'ACCEPTED' && lastCounterofferOfCurrent.state === 'ACCEPTED') {
                return setDeltas();
              }

              // 2 - if theres no counteroffer to apply, just return, but if theres a new state as well, first create clone
              if (!lastCounterofferOfUpdate) {
                if (originalState !== state) {
                  createClone();
                }
                return;
              }

              // 3

              // 3a. - if theres no previous counteroffer, just push the new one to the array and apply
              if (!lastCounterofferOfCurrent) {
                $scope.request.counteroffers.push(lastCounterofferOfUpdate);
                createClone();
                // 4.
                $scope.$emit('REQUEST_CHANGE', { clone: $scope.clone, counteroffer: lastCounterofferOfUpdate });
                return;
              }

              // 3b
              // setDeltas();

              // 3bi & 3bii - if times aren't equal or if they are but the states are different (ready vs init), apply changes
              if ((lastCounterofferOfCurrent.time !== lastCounterofferOfUpdate.time) ||
                (lastCounterofferOfCurrent.time === lastCounterofferOfUpdate.time && lastCounterofferOfCurrent.state !== lastCounterofferOfUpdate.state)) {
                set($scope.request, 'counteroffers.' + ($scope.request.counteroffers.length - 1), lastCounterofferOfUpdate);
                createClone();
                // 4.
                $scope.$emit('REQUEST_CHANGE', { clone: $scope.clone, counteroffer: lastCounterofferOfUpdate });
                return;
              }

            }).catch(unwrapError);
        }, REQUEST_LISTENER_INTERVAL);
      }
    }]
  };
};
