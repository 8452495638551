import * as angular from 'angular';
import modals from './modals';
import { each } from 'lodash';

import { FrozenRegistrationComponent } from './frozen-registration.component';
import { VenueUserRegistrationComponent } from './venue-user-registration/venue-user-registration.component';
import { selectCompanyComponent } from './select-company/select-company.component';
import { editCompanyComponent } from './edit-company/edit-company.component';
import { CompleteRegistration } from './complete-registration';
import { ObscuredLoginComponent } from './obscured-login.component';
import { MagicLinkVerification } from './magic-link-verification';

const m = angular.module('auth', []);

m.component('frozenRegistration', FrozenRegistrationComponent)
  .component('venueUserRegistration', VenueUserRegistrationComponent)
  .component('selectCompany', selectCompanyComponent)
  .component('editCompany', editCompanyComponent)
  .component('completeRegistration', CompleteRegistration)
  .component('obscuredLogin', ObscuredLoginComponent)
  .component('magicLinkVerification', MagicLinkVerification);

each(modals, function(modal, name) {
  m.factory(name, modal);
});

module.exports = m.name;
