import mongoose from 'mongoose';
import { GetAccountInfoResponse } from 'server/api/account/models';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { AccountActionQuery, DAccount } from 'spc/lib/database/types/accounts';
export class MembershipAccount {
    BASE_URL: string;
    constructor(private API_ROOT: string, private $http: ng.IHttpService) {
        this.BASE_URL = `${API_ROOT}/account`;
    }

    public getAccount = (): ng.IPromise<GetAccountInfoResponse> => {
        return this.$http.get(this.BASE_URL)
            .then((res: { data: GetAccountInfoResponse }) => {
                return res.data;
            });
    }

    public updateAccountMembers = ({ accountId, user, query }: { accountId: mongoose.Types.ObjectId | string, user: RawBaseUser, query: AccountActionQuery}) => {
        return this.$http.put(`${this.BASE_URL}/${accountId}/members`, { query, user })
            .then((res) => {
                return res.data;
        });
    }
    public resendEmail = ({ accountId, user }: { accountId: mongoose.Types.ObjectId | string, user: RawBaseUser }) => {
        return this.$http.put(`${this.BASE_URL}/${accountId}/reinvite`, { user })
            .then((res) => {
                return res.data;
        });
    }

    public requestPremiumAccount = () => {
        return this.$http.post(`${this.BASE_URL}/premium-account-Inquiry`, {})
            .then((res: { data: Express.Response}) => {
                return res.data;
        });
    }

    public updateAccountLogo = ({ accountId, updates }: { accountId: mongoose.Types.ObjectId, updates?: any}) => {
        return this.$http.put(`${this.BASE_URL}/${accountId}/logo-update`, { updates })
            .then((res) => {
                return res.data;
        });
    }
}
