import { cloneDeep, get } from 'lodash';
import { ApiService } from '../shared/api/api.service';

class AssetsLogoUploadController {
  ui: {
    buttonText?: string;
    errorMessage?: string;
  } = {};
  uploadedLogo: { url: string; title: string; fileSize: string; createdAt: Date, uploadedBy: string } | null = null;
  account: any;
  showUploadBtn: boolean = false;

  constructor(
    private $scope: ng.IScope,
    private $cloudinary: any,
    private $api: ApiService,
    private $user,
    private $clickOk
  ) {
    'ngInject';
  }

  $onInit() {
    this.ui.buttonText = 'No File Chosen';
    this.setupEventListeners();
  }

  public setupEventListeners() {
    this.$scope.$on('FILE_SIZE_EXCEEDED', () => {
      this.ui.buttonText = 'No File Chosen';
    });

    this.$scope.$on('FILE_UPLOADING', () => {
      this.ui.buttonText = 'Uploading...';
    });

    this.$scope.$on('FILE_UPLOADED', () => {
      this.showUploadBtn = true;
    });

    this.$scope.$on('FILE_TYPE_ERROR', () => {
      this.ui.errorMessage = 'Only JPG and PNG files are allowed!';
    });
  }

  public handleFileSizeValidation(file: any) {
    const fileSizeKB = file.size / 1024;
    const maxSizeKb = 500;
    if (fileSizeKB > maxSizeKb) {
      this.ui.errorMessage = `File size exceeds ${maxSizeKb} KB. Please upload a smaller file.`;
      this.ui.buttonText = 'No File Chosen';
      return;
    }
  }

  public handleLogoUpload(url: string, title: string, bytes: number, createdAt: Date) {
    const fileSizeKB = bytes / 1024;
    const fileSize = `${fileSizeKB.toFixed(1)} KB`;
    const uploadedBy = this.$user.$.fullName;
    this.uploadedLogo = { url, title, fileSize, createdAt, uploadedBy };
    this.ui.errorMessage = '';
  }

  public deleteUploadedLogo() {
    this.uploadedLogo = null;
    this.ui.buttonText = 'No File Chosen';
    this.showUploadBtn = false;
    this.ui.errorMessage = '';
  }

  public saveLogoToAccount() {
    if (!this.uploadedLogo) {
      return;
    }
    const updates = this.createLogoUpdateRequestData(this.uploadedLogo);
    this.$api.MembershipAccount.updateAccountLogo({ accountId: this.account._id.toString(), updates })
      .then((response) => {
        this.account = cloneDeep(response['account']);
        this.showUploadBtn = false;
        this.ui.buttonText = 'No File Chosen';
      })
      .catch((error) => {
        this.ui.errorMessage = error;
      });
  }

  public createLogoUpdateRequestData(uploadedLogo) {
    const updates = {
      logo: {
        uploadedAt: uploadedLogo.createdAt,
        url: uploadedLogo.url,
        fileSize: uploadedLogo.fileSize,
        title: uploadedLogo.title,
        uploadedBy: uploadedLogo.uploadedBy
      },
    };
    return updates;
  }

  public deleteLogoToAccount() {
    const showCancel = true;
    const updates = {
      logo: null
    };
    return this.$clickOk('Are you sure you want to delete this Logo?', showCancel)
      .then((response) => {
        if (get(response, 'value.cancel')) {
          return;
        }
        this.$api.MembershipAccount.updateAccountLogo({ accountId: this.account._id.toString(), updates })
          .then((response) => {
            this.account = cloneDeep(response['account']);
          })
          .catch((error) => {
            this.ui.errorMessage = error;
          });
      });
  }
}

export const assetsLogoUploadComponent = {
  controller: AssetsLogoUploadController,
  template: require('./assets-logo-upload.component.jade'),
  bindings: {
    account: '<',
  },
};
