import { DUser, RawUser } from 'spc/lib/database/types/user';
import { ApiService } from 'spc/shared/api/api.service';
import { UserService } from 'spc/services/user.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import ENUMS from 'common/dist/enums';
import set from 'lodash/set';
import get from 'lodash/get';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { AUTHENTICATION_TABS } from 'spc/constants/ENUMS/authenticationTabs';
import { ANALYTICS_EVENTS } from 'spc/constants/ENUMS/analyticsEvents';
import { every } from 'lodash';

/**
 * Use Cases
 *
 * user is unauthenticated in all cases
 *
 * 1. User enters client's email
 *   - Lead's client is not registered
 *   - Lead's client is registered
 * 2. User does not enter client's email
 *   - User has a registered account
 *   - User does not have a registered account
 *
 */

class LeadAuthenticationController {
  AUTHENTICATION_TABS = AUTHENTICATION_TABS;
  state: AUTHENTICATION_TABS = AUTHENTICATION_TABS.CHECK_EMAIL;
  lead: { clients: { user: RawBaseUser }[] };
  cities: { name: string, value: string }[];
  close: () => any;
  auth: {
    isBaseUser: boolean;
    user: RawUser;
    email: string;
    isValid: boolean;
    password: string;
    repeatPassword?: string;
    errorMessage: string;
  };
  isNewUser: boolean;
  data: {
    password: string;
    showPassword: boolean;
  };
  ui: {
    loading: boolean,
    resendMagicLinkTimer: number
    registerTab: AUTHENTICATION_TABS.PASSWORD | AUTHENTICATION_TABS.MAGIC_LINK
  } = {
    loading: false,
    resendMagicLinkTimer: 0,
    registerTab: AUTHENTICATION_TABS.PASSWORD
  };
  resendMagicLinkTimeout;

  constructor(
    private $api: ApiService,
    private unwrapError,
    private $analytics,
    private $user: UserService,
    private spPardot: SpPardotService,
    private $timeout
    ) {
    'ngInject';
    this.cities = ENUMS.acceptableUserCities;
  }

  submitEmail = () => {
    this.ui.loading = true;

    this.$api.Auth.verifyEmail(this.auth.email)
      .then((response) => {
        this.auth.isValid = get<boolean>(response, 'data.valid', false);
      })
      .then(() => this.findUser())
      .then(() => {
        this.cancelResendTimer();
        this.auth.errorMessage = '';
        // Handle user redirect to log in or register tab
        if (this.auth.user && !this.auth.isBaseUser) {
          this.isNewUser = false;
          return this.state = AUTHENTICATION_TABS.LOG_IN;
        } else {
          this.isNewUser = true;
          if (!this.auth.user) {
            set(this.auth, 'user.profile.email', this.auth.email);
          }
          // If there is a user who is a base user
          // or if there is no user
          // both should be taken to the registration page
          // If state is register, set default registration method as PASSWORD - as base user then cannot toggle register methods
          this.ui.registerTab = AUTHENTICATION_TABS.PASSWORD;
          return this.state = AUTHENTICATION_TABS.REGISTER;
        }
      })
      .catch((error) => {
        this.auth.isValid = error.data.valid;
        this.unwrapError(error);
      })
      .finally(() => {
        this.ui.loading = false;
      });
  }

  loginWithPassword = () => {
    if (!this.auth.isValid) {
      return;
    }

    this.auth.errorMessage = '';
    this.$user.passwordLogin({ email: this.auth.email, password: this.auth.password })
      .then((res) => {
        this.handleLoginSuccess({ user: res.user });
      })
      .catch((error) => {
        this.auth.errorMessage = get(error, 'data.error');
        this.unwrapError(error);
      });
  }

  loginWithMagicLink = () => {
    if (!this.auth.email) {
      return;
    }

    this.ui.loading = true;
    this.cancelResendTimer();
    this.auth.errorMessage = '';

    return this.$user.magicLinkLogin({ email: this.auth.email, onLoginSuccess: this.handleLoginSuccess })
      .then(() => {
        this.state = AUTHENTICATION_TABS.MAGIC_LINK_SUCCESS;
        this.startResendMagicLinkTimer(30);
      })
      .catch((error) => {
        this.auth.errorMessage = get(error, 'data.error');
        this.unwrapError(error);
      })
      .finally(() => {
        this.ui.loading = false;
      });
  }

  startResendMagicLinkTimer = (timer?: number) => {
    this.ui.resendMagicLinkTimer = timer || this.ui.resendMagicLinkTimer;

    if (this.ui.resendMagicLinkTimer > 0) {
      this.ui.resendMagicLinkTimer = this.ui.resendMagicLinkTimer - 1;
      this.resendMagicLinkTimeout = this.$timeout(this.startResendMagicLinkTimer, 1000);
    }
  }

  cancelResendTimer () {
    if (this.resendMagicLinkTimeout) {
      this.$timeout.cancel(this.resendMagicLinkTimeout);
      this.ui.resendMagicLinkTimer = 0;
    }
  }

  handleLoginSuccess = ({ user }) => {
    this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth['loggedIn']);
    this.spPardot.trackUser({ user, event: 'reco registration' });
    this.close();
  }

  handleRegisterSuccess = ({ user, token }) => {
    this.$analytics.clearReferrerData();
    this.$user.setUser(user, token);
    this.spPardot.trackUser({ user, event: 'reco registration' });
    this.close();
  }

  sendRegistrationMagicLink = () => {
    this.ui.loading = true;
    this.auth.errorMessage = '';
    this.auth.password = '';
    this.cancelResendTimer();

    const user = {
      guest: this.auth.user,
      password: this.auth.password,
      referrer: this.$analytics.getReferrerData()
    };

    return this.$user.magicLinkRegistration({ user, onRegisterSuccess: this.handleRegisterSuccess })
      .then(() => {
        this.state = AUTHENTICATION_TABS.MAGIC_LINK_SUCCESS;
        this.ui.loading = false;
        this.startResendMagicLinkTimer(30);
      })
      .catch((error) => {
        this.auth.errorMessage = get(error, 'data.error.message');
        this.ui.loading = false;
        this.unwrapError(error);
      });
  }

  register = () => {
    this.ui.loading = true;
    this.auth.errorMessage = '';
    this.$api.Auth.register({
      guest: this.auth.user,
      password: this.auth.password,
      referrer: this.$analytics.getReferrerData()
    }).then((response) => {
      const { data: { user, token } }  = response;
      this.handleRegisterSuccess({ user, token });
    }).catch((error) => {
      this.auth.errorMessage = get(error, 'data.error.message');
      this.unwrapError(error);
    })
    .finally(() => {
      this.ui.loading = false;
    });
  }

  findUser = () => {
    return this.$api.Auth.findUser(this.auth.email)
      .then((data) => {
        this.auth.isBaseUser = data.isBaseUser;
        this.auth.user = data.user;
        this.auth.password = '';
      });
  }

  isClientEmail = (email) => {
    const emails = this.lead.clients.map(client => client.user.profile.email);

    return emails.includes(email.toLowerCase());
  }

  findPrimaryCity = (cityValue) => {
    const cityObj = this.cities.find(city => city.value === cityValue);
    if (cityObj) {
      return cityObj.name;
    }
  }

  selectPrimaryCity = (city) => {
    set(this.auth, 'user.profile.city', city);
  }

  sendVerifyAccountMagicLink = () => {
    this.ui.loading = true;
    this.auth.errorMessage = '';
    this.cancelResendTimer();

    return this.$user.verifyAccountWithMagicLink({ user: this.auth.user, onRegisterSuccess: this.handleRegisterSuccess })
      .then((res) => {
        this.state = AUTHENTICATION_TABS.MAGIC_LINK_SUCCESS;
        this.startResendMagicLinkTimer(30);
      })
      .catch((error) => {
        this.auth.errorMessage = get(error, 'data.error.message');
        this.unwrapError(error);
      })
      .finally(() => {
        this.ui.loading = false;
      });
  }

  resendMagicLink = () => {
    if (this.isNewUser) {
      this.auth.isBaseUser ? this.sendVerifyAccountMagicLink() : this.sendRegistrationMagicLink();
    } else {
      this.loginWithMagicLink();
    }
  }

  backToAuthenticationTab = (): void => {
    if (!this.isNewUser) {
      this.state = AUTHENTICATION_TABS.LOG_IN;
    } else {
      this.state = AUTHENTICATION_TABS.REGISTER;
    }
  }

  toggleRegisterTab = (): void => {
    const currentRegisterTab = this.ui.registerTab;
    this.ui.registerTab = currentRegisterTab === AUTHENTICATION_TABS.PASSWORD ? AUTHENTICATION_TABS.MAGIC_LINK : AUTHENTICATION_TABS.PASSWORD;
  }

  hasEnteredAllFields = function () {
    const requiredFields = [
      get(this.auth, 'user.profile.name.first'),
      get(this.auth, 'user.profile.name.last'),
      get(this.auth, 'user.profile.email'),
      get(this.auth, 'user.profile.city')
    ];

    if (this.ui.registerTab === AUTHENTICATION_TABS.PASSWORD) {
      requiredFields.push(get(this.auth, 'password'));
    }

    if (!this.auth.isBaseUser) {
      return every(requiredFields);
    } else {
      return true;
    }
  };
}

export const leadAuthenticationComponent = {
  template: require('./lead-authentication.component.jade'),
  controller: LeadAuthenticationController,
  bindings: {
    lead: '<',
    close: '&'
  }
};
