import * as angular from 'angular';

import { organizeAttachmentsModal } from './attachments-modal';
import { attachmentUploadDirective } from './dropzone.directive';
import { venueAttachmentModal } from './venue-attachment-modal';
import { messageAttachmentsComponent } from './message-attachments.component';
import { messageAttachmentsModal } from './message-attachments.modal';
import { contractAttachmentsModal } from './contract-attachments.modal';
import { contractAttachmentsComponent } from './contract-attachments.component';
import { logoUploadDirective } from './assets-dropzone.directive';
import { AttachmentModalServices } from './attachment-modal.services';
import { logoUploadModal } from './logo-upload-modal';
// Vendor dependencies
const ngSortableModule = require('../../vendor/ngSortable');

const m = angular.module('attachments', [ngSortableModule(), 'services']);

m.factory('organizeAttachmentsModal', organizeAttachmentsModal);
m.directive('attachmentUpload', attachmentUploadDirective);
m.directive('logoUpload', logoUploadDirective);
m.component('messageAttachments', messageAttachmentsComponent);
m.service('messageAttachmentsModal', messageAttachmentsModal);
m.service('venueAttachmentModal', venueAttachmentModal);
m.service('logoUploadModal', logoUploadModal);
m.component('contractAttachments', contractAttachmentsComponent);
m.service('contractAttachmentsModal', contractAttachmentsModal);
m.service('attachmentModalServices', AttachmentModalServices);

module.exports = m;
