import { each, get } from 'lodash';
import debounce from 'lodash/debounce';
import { searchLocations } from 'common/dist/enums';
import VenueSearchMap from './venue-search-map.service';
/**
 * Stateful component that tracks search filters and applies them as users click on them.
 *
 * NOTE : USES `$scope` IN THE NGDIALOG. THIS SHOULD EVENTUALLY BE REMOVED
 */

const DEBOUNCE_LIMIT = 500;

let ctrl;
class VenueSearchFiltersComponent {
  locations: { [city: string]: { [neighborhood: string]: number } };

  constructor(
    unwrapError,
    private VenueSearchFiltersService,
    VenueSearchMapService: VenueSearchMap,
    ngDialog,
    $scope
  ) {
    'ngInject';
    ctrl = this;
    ctrl.unwrapError = unwrapError;
    ctrl.mapService = VenueSearchMapService;
    ctrl.ngDialog = ngDialog;
    ctrl.ui = {};
    ctrl.$scope = $scope;
    ctrl.neighborhoodLoaded = false;
  }

  // Lifecycle Hooks

  $onDestroy() {
    ctrl.filterService.clean();
  }

  $onInit = () => {
    ctrl.filterService = ctrl.search
      ? this.VenueSearchFiltersService.activate(ctrl.search)
      : null;
    ctrl.search.state.on('LOADED', () => {
      this.setNeighborhoods();
      ctrl.neighborhoodLoaded = true;
    });
  }

  // Public Functions

  openSearchFilterModal() {
    let childCtrl;
    const parentCtrl = ctrl;

    return parentCtrl.ngDialog
      .open({
        template: require(`./venue-search-filter-modal.jade`),
        overlay: true,
        plain: true,
        controller: class VenueSearchFilterModal {
          constructor(VenueSearchFiltersService, $scope) {
            'ngInject';
            childCtrl = this;
            childCtrl.filterService = VenueSearchFiltersService;
            childCtrl.$scope = $scope;
            childCtrl.locations = ctrl.locations;
            childCtrl.loading = false;
          }

          /**
           * Wrapper around `$scope.closeThisDialog`. useful once we eliminate `$scope`
           *
           * @public
           * @return {Promise}
           */
          closeThisDialog() {
            return childCtrl.$scope.closeThisDialog();
          }

          /**
           * Toggles filter and actually runs query in the background
           *
           * @public
           * @param {String} path
           * @param {Any} value
           * @param {String} schema
           * @param {Boolean} toPush
           * @return {Promise}
           */
          toggleFilterArray(path, value, schema, toPush) {
            childCtrl.loading = true;
            return parentCtrl
              .toggleFilterArray(path, value, schema, toPush)
              .then(() => parentCtrl.redrawMarkers())
              .catch(parentCtrl.unwrapError)
              .finally(() => {
                childCtrl.loading = false;
              });
          }

          toggleContentFilter(path, value, schema, toPush) {
            childCtrl.loading = true;
            return parentCtrl
              .toggleContentFilter(path, value, schema, toPush)
              .then(() => parentCtrl.redrawMarkers())
              .catch(parentCtrl.unwrapError)
              .finally(() => {
                childCtrl.loading = false;
              });
          }

          toggleNeighborhoodFilter(path, value, schema, toPush) {
            childCtrl.loading = true;
            return parentCtrl
              .toggleNeighborhoodFilter(path, value, schema, toPush)
              .then(() => parentCtrl.redrawMarkers())
              .catch(parentCtrl.unwrapError)
              .finally(() => {
                childCtrl.loading = false;
              });
          }

          shouldDisabled(path, value, schema) {
            return parentCtrl.shouldDisabled(path, value, schema);
          }

          canSeeFilterBucket(path, schema) {
            return parentCtrl.canSeeFilterBucket(path, schema);
          }

          showContentFilter(type) {
            return parentCtrl.showContentFilter(type);
          }

          /**
           * Clear all filters and then close dialog
           *
           * @public
           * @return {Promise}
           */
          clearAllFilters() {
            return parentCtrl
              .clearAllFilters()
              .then(() => childCtrl.closeThisDialog())
              .catch(parentCtrl.unwrapError);
          }
        },
        bindToController: true,
        controllerAs: '$ctrl',
        className: 'ngdialog-theme-small info-modal',
      })
      .closePromise.catch(ctrl.unwrapError);
  }

  /**
   * Because the text search functionality is inside this component, we need to duplicate a bit
   * of logic so this is just executing the search service's text search function
   *
   * @public
   * @return {Promise}
   */
  textSearch() {
    const zoom = get(ctrl.search.aliases[0], 'map.zoom', 12);
    const center = get(ctrl.search.aliases[0], 'map.center');
    if ( zoom && center) {
      ctrl.mapService.resetMap(zoom, center);
    }
    return ctrl.search
      .applyTextSearch()
      .then(() => ctrl.redrawMarkers())
      .catch(ctrl.unwrapError);
  }

  debouncedTextSearch = debounce(() => {
    return this.textSearch();
  }, DEBOUNCE_LIMIT);

  redrawMarkers() {
    ctrl.mapService.drawMarkers(ctrl.search.venues, doc =>
      ctrl.onSelectWindow({ doc })
    );
  }

  /**
   * Clears all filters and redraws markers on map
   *
   * @public
   * @return {Promise}
   */
  clearAllFilters() {
    const zoom = ctrl.search.aliases[0].map.zoom;
    const center = ctrl.search.aliases[0].map.center;

    return ctrl.filterService
      .clearAllFilters()
      .then(() => {
        ctrl.mapService.resetMap(zoom, center);
        ctrl.redrawMarkers();
      })
      .catch(ctrl.unwrapError);
  }

  /**
   * Pushes filters to `ctrl.stateManager.enabledFilters` and to relevant search bucket,
   * and then applies the filters
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {String} schema
   * @param {Boolean} toPush - whether or not to push to the array
   * @return {Promise}
   */
  toggleFilterArray(path, value, schema, toPush) {
    return ctrl.filterService
      .toggleFilterArray(path, value, schema, toPush)
      .then(() => ctrl.redrawMarkers())
      .catch(ctrl.unwrapError);
  }

  toggleContentFilter({ path, value, schema, toPush }) {
    return ctrl.filterService
      .toggleFullVenue({ path, value, schema, toPush })
      .then(() => ctrl.redrawMarkers())
      .catch(error => ctrl.unwrapError(error));
  }

  toggleNeighborhoodFilter({ city, neighborhood, path, schema }) {
    return ctrl.filterService
      .toggleNeighborhoodFilter({ city, neighborhood, path, schema })
      .then(() => ctrl.redrawMarkers())
      .catch(error => ctrl.unwrapError(error));
  }

  selectParentCity({ city, neighborhoods, path, schema }) {
    return ctrl.filterService
      .selectParentCity({ city, neighborhoods, path, schema })
      .then(() => ctrl.redrawMarkers())
      .catch(error => ctrl.unwrapError(error));
  }

  deselectParentCity({ city, path, schema }) {
    return ctrl.filterService
      .deselectParentCity({ city, path, schema })
      .then(() => ctrl.redrawMarkers())
      .catch(error => ctrl.unwrapError(error));
  }

  clearFilter(path, schema) {
    return ctrl.filterService
      .clearFilter(path, schema)
      .then(() => ctrl.redrawMarkers())
      .catch(ctrl.unwrapError);
  }

  /**
   * Determines if a bucket for a given path exists in `ctrl.search.filters`
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {String} schema
   * @return {Boolean}
   */
  isFilterEnabledArray(path, value, schema) {
    return ctrl.filterService.isFilterEnabledArray(path, value, schema);
  }

  isNeighborhoodFilterEnabled({ city, neighborhood, schema, path }) {
    return ctrl.filterService.isNeighborhoodFilterEnabled({
      city,
      neighborhood,
      schema,
      path,
    });
  }

  isCityFilterEnabled(city) {
    return ctrl.filterService.isCityFilterEnabled(city);
  }

  canSeeFilterBucket(path, schema) {
    if (schema === '_amenities') {
      return true;
    }
    if (path === 'admin.network') {
      return true;
    }
    // Below condition disabled because of client wants to show menuprice filter to all users.
    // if (path === 'menus.data.priceInCents') {
    //   return ctrl.isAdmin && ctrl.filterService.bucketHasCount(path, schema);
    // }
    return ctrl.filterService.bucketHasCount(path, schema);
  }
  showContentFilter(type) {
    if (type === 'Venue Partners' && !ctrl.filterService.search.hasAccount) {
      return false;
    }
    else {
      return true;
    }
  }

  canSelectAll({ city, neighborhoods }) {
    return ctrl.filterService.canSelectAll({ city, neighborhoods });
  }

  canDeselectAll(city) {
    return ctrl.filterService.canDeselectAll(city);
  }
  /**
   * Gets initial counts for a filters
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {String} schema
   * @return {Number}
   */
  getInitialCount(path, value, schema) {
    return ctrl.filterService.getInitialCount(path, value, schema);
  }

  /**
   * Gets count for a given path and value for a filters
   * i.e. Gets the count for number of venues/spaces in Chelsea neighborhood
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {String} schema
   * @return {Number}
   */
  shouldDisabled(path, value, schema) {
    if (['data.ratingAndReviews', 'data.accolades'].includes(path) && !ctrl.filterService.search.hasAccount) {
      return true;
    }
    return !this.getCount(path, value, schema);
  }

  getCount(path, value, schema) {
    return ctrl.filterService.getCount(path, value, schema);
  }

  setNeighborhoods = (): void => {
    const locations = ctrl.filterService.getNeighborhoods();
    if (!locations) {
      return;
    }

    const cities = ctrl.search.aliases.map(alias => alias.city);
    const formattedLocations = {};
    cities.forEach((city) => {
      if (locations[city]) {
        const neighborhoods = Object.keys(locations[city]).sort();
        formattedLocations[city] = neighborhoods.reduce(
          (location, neighborhood) => {
            location[neighborhood] = locations[city][neighborhood];
            return location;
          },
          {}
        );
      }
    });

    ctrl.locations = formattedLocations;
  }
}

export default {
  template: require('./venue-search-filters.jade'),
  bindToController: true,
  bindings: {
    search: '<',
    showMap: '<',
    isAdmin: '<',
    toggleMap: '&',
    onSelectWindow: '&',
    calledFromShell: '<'
  },
  controller: VenueSearchFiltersComponent,
};
