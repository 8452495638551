import { every, get, set } from 'lodash';
import { ANALYTICS_EVENTS } from 'spc/constants/ENUMS/analyticsEvents';
import { AUTHENTICATION_TABS } from 'spc/constants/ENUMS/authenticationTabs';
import { ApiService } from 'spc/shared/api/api.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';

class RecoRequestAuthController {
  message: string;
  AUTHENTICATION_TABS = AUTHENTICATION_TABS;
  auth: {
    isEmailValid?: boolean;
    clientEmail?: string;
    isBaseUser?: boolean;
    user?: any
    password?: string;
    errorMessage: string;
    showPassword: boolean;
    isMagicLinkSent?: boolean;
  } = {
    errorMessage: '',
    showPassword: false
  };
  setClient: ({ client, isBaseUser }) => void;
  onAuthSubmit: (submitDetails) => void;
  venueName: string;
  ui: {
    authTab: AUTHENTICATION_TABS
    authLoginTab: AUTHENTICATION_TABS,
    loading: boolean,
    resendMagicLinkTimer: number
  } = {
    authTab: AUTHENTICATION_TABS.CHECK_EMAIL,
    authLoginTab: AUTHENTICATION_TABS.PASSWORD,
    loading: false,
    resendMagicLinkTimer: 0
  };
  resendMagicLinkTimeout;
  constructor(
    private $api: ApiService,
    private $user,
    private unwrapError,
    private $analytics,
    private $timeout,
    private spPardot: SpPardotService
  ) {
    'ngInject';
  }

  checkEmail = () => {
    this.auth.errorMessage = '';
    this.auth.isMagicLinkSent = false;
    return this.$api.Auth.verifyEmail(this.auth.clientEmail)
      .then((res) => {
        this.auth.isEmailValid = get(res, 'data.valid');
        return this.findUser();
      })
      .then(() => {
        if (get(this.auth, 'user')) {
          this.cancelResendTimer();
          this.ui.authTab = this.auth.isBaseUser ? this.AUTHENTICATION_TABS.REGISTER : this.AUTHENTICATION_TABS.LOG_IN;
        } else {
          // If no user profile, initialize a new profile with the email and set Referrer data
          set(this.auth, 'user.profile.email', this.auth.clientEmail);
          set(this.auth, 'user.internal.referrer', this.$analytics.getReferrerData());
          this.ui.authTab = this.AUTHENTICATION_TABS.REGISTER;
        }

        this.setClient({ client: this.auth.user, isBaseUser: this.auth.isBaseUser });
      })
      .catch((error) => {
        this.auth.isEmailValid = get(error, 'data.valid');
        if (!this.auth.isEmailValid) {
          this.auth.errorMessage = `The email is invalid. Please check to make sure there's no typo.`;
        }
        this.unwrapError(error);
      });
  }

  changeLoginAuthTab = (tab) => {
    this.auth.errorMessage = '';
    this.ui.authLoginTab = tab;
  }

  backToEmail = () => {
    this.ui.authTab = this.AUTHENTICATION_TABS.CHECK_EMAIL;
  }

  findUser = () => {
    return this.$api.Auth.findUser(this.auth.clientEmail)
      .then((userData) => {
        this.auth = { ...this.auth, ...userData };
      })
      .catch(error => this.unwrapError(error));
  }

  handlePasswordLogin = () => {
    this.auth.errorMessage = '';

    return this.$user.passwordLogin({ email: this.auth.clientEmail, password: this.auth.password })
      .then(() => {
        return this.handleLoginSuccess();
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      });
  }

  handleMagicLinkLogin = () => {
    this.ui.loading = true;
    this.auth.errorMessage = '';
    this.cancelResendTimer();
    this.auth.isMagicLinkSent = false;

    return this.$user.magicLinkLogin({ email: this.auth.clientEmail, onLoginSuccess: this.handleLoginSuccess })
      .then(() => {
        this.auth.isMagicLinkSent = true;
        this.startResendMagicLinkTimer(30);
      })
      .catch((error) => {
        this.auth.errorMessage = error.data.error;
        this.unwrapError(error);
      })
      .finally(() => {
        this.ui.loading = false;
      });
  }

  handleLoginSuccess = () => {
    this.$analytics.$trackEvent(ANALYTICS_EVENTS.auth['loggedIn']);
    this.spPardot.trackUser({ event: 'obscured venue login' });
  }

  checkRequiredFields = (isBaseUser) => {
    const commonFields = [
      get(this.auth, 'user.profile.name.first'),
      get(this.auth, 'user.profile.name.last'),
      get(this.auth, 'user.profile.email')
    ];

    const requiredFields = isBaseUser
      ? commonFields
      : [...commonFields, get(this.auth, 'password')];

    return every(requiredFields);
  }

  toggleShowPassword = () => {
    return this.auth.showPassword = !this.auth.showPassword;
  }

  startResendMagicLinkTimer = (timer?: number) => {
    this.ui.resendMagicLinkTimer = timer || this.ui.resendMagicLinkTimer;

    if (this.ui.resendMagicLinkTimer > 0) {
      this.ui.resendMagicLinkTimer = this.ui.resendMagicLinkTimer - 1;
      this.resendMagicLinkTimeout = this.$timeout(this.startResendMagicLinkTimer, 1000);
    }
  }

  cancelResendTimer () {
    if (this.resendMagicLinkTimeout) {
      this.$timeout.cancel(this.resendMagicLinkTimeout);
      this.ui.resendMagicLinkTimer = 0;
    }
  }
}

export const RecoRequestAuthComponent = {
  template: require('./reco-request-auth.component.jade'),
  controller: RecoRequestAuthController,
  bindings: {
    setClient: '&',
    onAuthSubmit: '&',
    venueName: '<'
  }
};
