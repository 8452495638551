
const Dropzone = require('dropzone');

/**
 * This directive needs to be given a on-upload function
 * */

export const logoUploadDirective = () => {

  interface DropzoneScope extends ng.IScope {
    handleFileSizeValidation: ({ file }: { file: any }) => any;
    dropzone: any;
    onUpload: ({ url, title, bytes, created_at }: { url: string, title: string, bytes: string, created_at: Date }) => any;
  }

  return {
    template: require('../photos/logo-upload.jade'),
    scope: {
      onUpload: '&',
      handleFileSizeValidation: '&'
    },
    link: (scope: DropzoneScope, element: ng.IRootElementService, attrs) => {
      const el = element.find('.upload-container-logo')[0];
      const CLICKABLE_SELECTOR = '.upload-container-logo h3';
      scope.dropzone = new Dropzone(el, {
        uploadMultiple: false,
        clickable: element.find(CLICKABLE_SELECTOR)[0],
        acceptedFiles: '.jpg,.png',
        parallelUploads: 1,
        url: 'https://api.cloudinary.com/v1_1/dbwijvnzn/auto/upload?folder=attachments',
        previewTemplate: '<div class="dz-progress">' +
          '<span class="dz-upload" data-dz-uploadprogress></span>' +
          '</div>'
      });

      scope.dropzone.on('error', function(file, errorMessage) {
        const allowedTypes = ['image/jpg', 'image/png'];
        if (!allowedTypes.includes(file.type)) {
          scope.$emit('FILE_TYPE_ERROR');
          scope.$apply();
          return;
        }
      });

      scope.dropzone.on('sending', function (file, xhr, formData) {
        if (file.size > 500 * 1024) {
          scope.handleFileSizeValidation({ file });
          scope.$emit('FILE_SIZE_EXCEEDED');
          scope.$apply();
          return;
        }
        formData.append('upload_preset', 'afliugec');
        scope.$emit('FILE_UPLOADING');
        scope.$apply();
      });

      scope.dropzone.on('success', (file, response) => {
        $(element).find('.dz-progress').fadeOut();
        scope.onUpload({ url: response.secure_url, title: response.original_filename, bytes: response.bytes, created_at: response.created_at });
        scope.$emit('FILE_UPLOADED');
        scope.$apply();
      });
    }
  };
};
