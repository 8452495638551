import { get } from 'lodash';
import { ApiService } from '../shared/api/api.service';

export const logoUploadModal = ['ngDialog', '$api', function (ngDialog, $api: ApiService) {
  return function (lead, account) {
    return ngDialog.open({
      template: require('./logo-upload-modal.jade'),
      className: 'ngdialog-theme-small',
      plain: true,
      overlay: true,
      controllerAs: '$ctrl',
      controller: ['$scope', '$cloudinary', '$user', function ($scope, $cloudinary, $user) {

        // Initialization function
        this.init = () => {
          this.account = account;
          this.lead = lead;
          this.user = $user.$;
          this.$cloudinary = $cloudinary;
          this.ui = {
            buttonText: 'No File Chosen',
            errorMessage: ''
          };
          this.uploadedLogo = null;
          this.showUploadBtn = false;
          this.isDefaultTeamLogo = get(this.account, 'logo') && get(this.account, 'logo.title') === get(this.lead, 'logo.title');
          this.setupEventListeners();
        };

        this.setupEventListeners = () => {
          $scope.$on('FILE_SIZE_EXCEEDED', () => {
            this.ui.buttonText = 'No File Chosen';
          });

          $scope.$on('FILE_UPLOADING', () => {
            this.ui.buttonText = 'Uploading...';
          });

          $scope.$on('FILE_UPLOADED', () => {
            this.showUploadBtn = true;
          });

          $scope.$on('FILE_TYPE_ERROR', () => {
            this.ui.errorMessage = 'Only JPG and PNG files are allowed!';
          });
        };

        this.handleFileSizeValidation = (file) => {
          const fileSizeKB = file.size / 1024;
          const maxSizeKb = 500;
          if (fileSizeKB > maxSizeKb) {
            this.ui.errorMessage = `File size exceeds ${maxSizeKb} KB. Please upload a smaller file.`;
            this.ui.buttonText = 'No File Chosen';
            return;
          }
        };

        this.handleLogoUpload = (url, title, bytes, createdAt) => {
          const fileSizeKB = bytes / 1024;
          const fileSize = `${fileSizeKB.toFixed(1)} KB`;
          this.uploadedLogo = { url, title, fileSize, createdAt };
          this.uploadedLogo.uploadedBy = this.user.fullName;
          this.ui.errorMessage = '';
        };

        this.deleteUploadedLogo = () => {
          this.uploadedLogo = null;
          this.ui.buttonText = 'No File Chosen';
          this.showUploadBtn = false;
          this.ui.errorMessage = '';
        };

        this.saveLogoToLead = async (accountLogo) => {
          try {
            if (accountLogo && this.isDefaultTeamLogo) {
              await this.updateLeadLogo(accountLogo);
              $scope.closeThisDialog();
              return;
            }

            if (!this.uploadedLogo) {
              $scope.closeThisDialog();
              return;
            }

            const updates = this.createLogoUpdateRequestData(this.uploadedLogo);
            this.updateLeadLogo(updates);
          } catch (error) {
            this.ui.errorMessage = error;
          }
        };

        this.updateLeadLogo = async (logoData) => {
          this.lead.logo = logoData.logo || logoData;
          $api.Leads.update({ lead: this.lead })
            .then((response) => {
              this.lead = response.lead;
              this.showUploadBtn = false;
              this.ui.buttonText = 'No File Chosen';
              $scope.closeThisDialog();
            });
        };

        this.createLogoUpdateRequestData = uploadedLogo => ({
          logo: {
            uploadedAt: uploadedLogo.createdAt,
            url: uploadedLogo.url,
            fileSize: uploadedLogo.fileSize,
            title: uploadedLogo.title,
            uploadedBy: uploadedLogo.uploadedBy,
          },
        });

        this.init();
      }]
    }).closePromise;
  };
}];
