import BookingRequestSchema from 'common/dist/schemas/BookingRequest';
import { get, findIndex, isNumber } from 'lodash';
import { RawScheduleItem } from '../../../../database/types/payment';
// constant
import { ANALYTICS_EVENTS } from '../../constants/ENUMS/analyticsEvents';

export default function() {
  return {
    template: require('./payment.jade'),
    scope: {
      conversation: '=',
      stateCtrl: '='
    },
    link: function(scope, element, attrs) {
      scope.formContainer = element.find('#payment-form')[0];
    },
    controller: ['$scope', '$api', '$braintree', 'ENUMS', '$rootScope', '$cloudinary', '$injector', 'braintreeHelpers', 'paymentHelpers', '$timeout', 'unwrapError', '$window', function($scope, $api, $braintree, ENUMS, $rootScope, $cloudinary, $injector, braintreeHelpers, paymentHelpers, $timeout, unwrapError, $window) {
      $scope.$cloudinary = $cloudinary;
      $scope.addNewCard = addNewCard;
      $scope.disableButton = disableButton;
      $scope.hideNewCardForm = hideNewCardForm;
      $scope.saveGratuityPercentage = saveGratuityPercentage;
      $scope.submitSelectedCard = submitSelectedCard;
      $scope.toggleEditGratuity = toggleEditGratuity;
      $scope.toggleCreatePaymentMethod = toggleCreatePaymentMethod;
      $scope.applyCode = applyCode;

      init();

      ///// Listeners
      $scope.$on('$destroy', $braintree.destroy);

      ///// Initialization Functions

      /**
       * Load request for payment details
       */
      function init() {
        // send request with conversation id, but retrieve only request by querying for requests with that conversation id

        $api.Payment.initialize({ getMethods: true, conversation: $scope.conversation }).
          then(initData).
          then(() => $scope.stateCtrl.$state('LOADED')).
          catch(unwrapError);
      }

      /**
       * Initialize data from AJAX response
       *
       * @param {ajaxResponse} contains request, conversation, guest
       */
      function initData(ajaxResponse) {
        $scope.bookingRequest = get(ajaxResponse, 'data.request');

        $scope.drinks = ($scope.bookingRequest.data.drinks || [])
          .filter(drink => !drink.isDeleted);

        // redirects if not confirmed
        paymentHelpers.checkState($scope.bookingRequest, 'ACCEPTED_GUEST');

        $scope.costBreakdownCents = $scope.bookingRequest.totalCostBreakdownCents;
        const hostedFields = braintreeHelpers.generateHostedFieldsOptions($scope);

        const extraMethods = {
          onPaymentMethodReceived: _submit,
          onError: braintreeHelpers.onError($scope)
        };

        $braintree.init(
          $scope.formContainer,
          get(ajaxResponse, 'data.token'),
          hostedFields,
          extraMethods
        );

        $scope.guest = get(ajaxResponse, 'data.guest');
        $scope.cards = ENUMS.payment.cards;
        $scope.cardParams = {};

        const paymentMethods = get<any[]>(ajaxResponse, 'data.paymentMethods');
        $scope.paymentMethods = paymentMethods;

        const indexOfDefault = findIndex(paymentMethods, function(method) {
          return method.default;
        });
        if (indexOfDefault > -1) {
          $scope.selectedCard = paymentMethods[indexOfDefault];
        } else {
          $scope.createPaymentMethod = true;
        }
        $scope.paymentState = 'READY';
      }

      ///// Public Functions

      function addNewCard() {
        $scope.selectedCard = null;
        $scope.createPaymentMethod = true;
        $scope.paymentState = 'READY';
      }

      function hideNewCardForm(method) {
        $scope.createPaymentMethod = false;
        $scope.selectedCard = method;
      }

      function submitSelectedCard() {
        _submit({ token: $scope.selectedCard.token });
      }

      function saveGratuityPercentage(percentage) {
        if (!isNumber(percentage)) {
          $scope.editGratuityPercentage = false;
          return;
        }

        if (percentage === $scope.bookingRequest.data.gratuityPercentage) {
          $scope.editGratuityPercentage = false;
          return;
        }
        $api.Requests.putGratuity($scope.bookingRequest._id, percentage).
          then(function(response) {
            $scope.bookingRequest = get(response, 'data.data');
            $scope.costBreakdownCents = $scope.bookingRequest.totalCostBreakdownCents;
            $scope.editGratuityPercentage = false;
          }).
          catch(unwrapError);
        }

        function toggleEditGratuity() {
          $scope.editGratuityPercentage = $scope.editGratuityPercentage ? false : true;
          if ($scope.editGratuityPercentage && !$scope._gratuityPercentage) {
            $scope._gratuityPercentage = $scope.bookingRequest.data.gratuityPercentage;
          }
        }

        function disableButton() {
          $scope.paymentState = 'LOADING';
        }

        function toggleCreatePaymentMethod (event) {
          event.stopPropagation();
          event.preventDefault();
          $scope.createPaymentMethod = !$scope.createPaymentMethod;
        }

        function applyCode (code) {
          if (!code || !code.length) {
            $scope.displayValidity = false;
            $scope.checkingCodeValidity = false;
            $scope.promoCode = null;
            $scope.isValidCode = false;
            return;
          }

          $scope.checkingCodeValidity = true;
          $scope.displayValidity = false;

          return $api.Payment.validatePromoCode({ code, venueId: $scope.bookingRequest.venue._id })
            .then((res) => {
              $scope.checkingCodeValidity = false;
              $scope.displayValidity = true;
              $scope.isValidCode = true;
              $scope.promoCode = code;
              $scope.promoSuccessMessage = res.message;
            })
            .catch((error) => {
              $scope.checkingCodeValidity = false;
              const errorMessage = get(error, 'data.error.message');
              if (errorMessage) {
                $scope.promoErrorMessage = errorMessage;
              }
              $scope.displayValidity = true;
              $scope.isValidCode = false;
              unwrapError(error);
            });
        }
        ///// Private functions

      /**
       * Tracks analytics event for user paying the deposit
       *
       * @param {Request} request
       */
      function _trackPaidDeposit(request) {
        const eventParams = {
          amountPaid: $scope.costBreakdownCents.deposit,
          total: $scope.costBreakdownCents.total,
          venue: get(request, 'venue.data.name'),
          venueCity: get(request, 'venue.data.address.city')
        };

        const eventName = $scope.createPaymentMethod ?
          ANALYTICS_EVENTS.payment.paidDepositWithNewCard :
          ANALYTICS_EVENTS.payment.paidDepositWithStoredCard;

        const eventAction = 'Payment';

        $injector.get('$analytics').$trackEvent(eventName, eventParams, eventAction);
      }

      /**
       * Braintree callback executed after tokenization of payment method
       *
       * @param {Payload} payload, contains nonce:string, type:string, details:object
       */

      interface Payment {
        type: string;
        details: any;
        token?: string;
        nonce?: string;
        createPaymentMethod?: any;
        cardParams?: any;
        promoCode: string;
      }

      function _submit(payload) {
        const payment: Payment = {
          type: payload.type,
          details: payload.details,
          promoCode: $scope.promoCode
        };

        if (payload.token) {
          payment.token = payload.token;
        } else if (payload.nonce) {
          payment.nonce = payload.nonce;
          payment.createPaymentMethod = $scope.createPaymentMethod;
          payment.cardParams = $scope.cardParams;
        }

        $api.Payment.submit($scope.bookingRequest._id.toString(), payment).
          then(function() {
            _trackPaidDeposit($scope.bookingRequest);
            $injector.get('$location').url('/client/conversation/' + $scope.conversation + '/confirmation');
          }).
          catch(function(error) {
            $scope.paymentState = 'READY';

            if (error.data.error === 'Declined' || error.data.error === 'Processor Declined') {
              $scope.paymentState = 'DISABLED';
              $timeout(() => {
                $window.location.reload();
              }, 8000);
            }

            $scope.error = {
              general: error.data.error,
              declined: error.data.error === 'Declined' || error.data.error === 'Processor Declined'
            };
            unwrapError(error);
          });
      }
    }]
  };
}
