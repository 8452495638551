import { debounce, every, get, set } from 'lodash';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import { SpPardotService } from 'spc/shared/sp-pardot/sp-pardot.service';
import { UserService } from 'spc/services/user.service';
import { ApiService } from 'spc/shared/api/api.service';
import { AUTHENTICATION_TABS } from 'spc/constants/ENUMS/authenticationTabs';

module.exports = function () {
  return {
    template: require('./registration.jade'),
    scope: {
      isEmbedded: '='
    },
    controller: ['$scope', '$location', '$user', '$api', '$attrs', 'ENUMS', '$timeout', '$injector', 'unwrapError', '$analytics', 'spPardot', 'welcomePromoCodeModel', function ($scope, $location, $user: UserService, $api: ApiService, $attrs, ENUMS, $timeout, $injector, unwrapError, $analytics: AnalyticsService, spPardot: SpPardotService, welcomePromoCodeModel) {
      $scope.cities = ENUMS.acceptableUserCities;
      $scope.user = $user;
      $scope.registration = { };
      $scope.data = { password: '', showPassword: false };
      $scope.userQueryEmail = $location.search().email || undefined;
      $scope.AUTHENTICATION_TABS = AUTHENTICATION_TABS;
      $scope.registrationTab = $scope.AUTHENTICATION_TABS.MAGIC_LINK;
      $scope.emitEvent = emitEvent;
      $scope.resendCountdown = 0;
      // Start newsletter input checked off (default to user wanting newsletter)
      $scope.wantsNewsletter = true;

      function emitEvent(eventName, params) {
        $scope.$emit(eventName, params);
      }

      function determineEvent () {
        const location = spPardot.determineLocation();
        return location ?  `${location} registration` : 'registration page';
      }

      function handleRegisterSuccess (res) {
        $analytics.clearReferrerData();
        $scope.user.setUser(res.user, res.token);
        spPardot.trackUser({ event: determineEvent() });
        $scope.$emit('REGISTER_SUCCESS', res);
        $('iframe').remove();
        welcomePromoCodeModel();
      }

      function startResendCountdown (timer: number) {
        $scope.resendCountdown = timer || $scope.resendCountdown;

        if ($scope.resendCountdown > 0) {
          $scope.resendCountdown = $scope.resendCountdown - 1;
          $scope.resendTimeout = $timeout(startResendCountdown, 1000);
        }
      }

      function cancelResendCountdown () {
        if ($scope.resendTimeout) {
          $timeout.cancel($scope.resendTimeout);
        }
      }

      async function checkBaseUserExistence (email: string) {
        const { isBaseUser } = await $api.Auth.findUser(email);
        return isBaseUser;
      }

      $scope.sendRegistrationMagicLink = async function () {
        try {
          $scope.disableButton = true;
          cancelResendCountdown();
          $scope.data.password = '';
          const userData = await validateAndProcessUserData();
          await $user.magicLinkRegistration({ user: userData, onRegisterSuccess: handleRegisterSuccess });
          $scope.registrationTab = $scope.AUTHENTICATION_TABS.MAGIC_LINK_SUCCESS;
          startResendCountdown(30);
        } catch (error) {
          $scope.error = get(error, 'data.error.message') || get(error, 'message');
          unwrapError(error);
        } finally {
          $scope.disableButton = false;
          $scope.$apply();
        }
      };

      // $scope.$on('IS_MODAL', () => $scope.isModal = true);
      $scope.register = async function () {
        try {
          $scope.disableButton = true;
          const userData = await validateAndProcessUserData();
          const { data } = await $api.Auth.register(userData);
          handleRegisterSuccess(data);
        } catch (error) {
          $scope.error = get(error, 'data.error.message') || get(error, 'message');
          unwrapError(error);
        } finally {
          $scope.disableButton = false;
          $scope.$apply();
        }
      };

      async function validateAndProcessUserData () {
        $scope.error = null;

        if ($scope.wantsNewsletter) {
          $scope.registration.profile.newsletters = ['Main'];
          $scope.registration.profile.newsletters.push($scope.registration.profile.city);
        } else {
          $scope.registration.profile.newsletters = [];
        }

        const theUser = $scope.registration;
        const userData = {
          guest: theUser,
          password: $scope.data.password,
          referrer: $analytics.getReferrerData()
        };

        set(userData, 'guest.company', get(theUser, 'company'));
        const isBaseUser = await checkBaseUserExistence(get(userData, 'guest.profile.email', ''));

        if (isBaseUser) {
          throw new Error(`An account with this email is already being set up. Log in to verify and continue.`);
        }

        return userData;
      }

      $scope.toggleNewsletter = function () {
        $scope.wantsNewsletter = !$scope.wantsNewsletter;
      };

      $scope.findPrimaryCity = (cityValue) => {
        const cityObj = $scope.cities.find(city => city.value === cityValue);
        if (cityObj) {
          return cityObj.name;
        }
      };

      $scope.selectPrimaryCity = function (city) {
        set($scope.registration, 'profile.city', city);
        $scope.primaryCity = $scope.cities[city];
      };

      $scope.selectCompany = (company) => {
        $scope.registration.company = company;
      };

      $scope.isValidEmail = debounce(function () {
        $api.Auth.verifyEmail($scope.registration.profile.email)
          .then(function (res) {
            $scope.email = {
              address: res.data.address,
              isValid: res.data.valid
            };
          })
          .catch(function (error) {
            $scope.email = {
              address: error.data.address,
              isValid: error.data.valid
            };
          });
      }, 500);

      $scope.hasEnteredAllFields = function () {
        const requiredFields = [
          get($scope.registration, 'profile.name.first'),
          get($scope.registration, 'profile.name.last'),
          get($scope.registration, 'profile.email'),
          $scope.email && $scope.email.isValid,
        ];

        if ($scope.registrationTab === $scope.AUTHENTICATION_TABS.PASSWORD) {
          requiredFields.push($scope.data.password);
        }

        requiredFields.push(get($scope.registration, 'profile.city'));
        return every(requiredFields);
      };

      $scope.getEmail = function () {
        return $scope.email;
      };

      $scope.changeRegistrationTab = function (tab: string) {
        $scope.registrationTab = tab;
      };

      $timeout(function () {
        $scope.$emit('RegistrationController');
      }, 0);
    }]

  };
};
