import get from 'lodash/get';
import debounce from 'lodash/debounce';

import { ApiService } from 'spc/shared/api/api.service';
import { RawCompany } from 'database/types/company';
import { DUser } from 'spc/lib/database/types/user';
import { UserService } from 'spc/services/user.service';

class SelectCompanyController {
  email: string;
  companies: RawCompany[];
  user: DUser;
  tabIndex: number;
  placeholder: string;
  selectCompany: (company: RawCompany) => void;
  ui: {
    creatingCompany?: boolean;
    editingCompany?: boolean;
    companyName?: string;
  };

  constructor(private $api: ApiService, private $timeout: ng.ITimeoutService, private $user: UserService) {
    'ngInject';
  }

  $onInit = () => {
    this.placeholder = this.placeholder || 'Company';
    this.ui = { editingCompany: false };
    if (get(this.user, 'company._id')) {
      this.select(this.user.company);
    }
  }

  $onChanges = (data) => {
    this.debouncedGetCompanies();
  }

  createCompany = () => {

    const company = {
      name: this.ui.companyName,
      domains: [this.getDomain()]
    };
    this.select(company);
  }

  select = (company) => {
    this.ui.companyName = company.name;
    this.selectCompany(company);
    this.ui.editingCompany = false;
  }

  searchCompanies = () => {
    if (!this.$user.isAdmin()) {
      return;
    }
    if (!this.ui.companyName || this.ui.companyName.length < 3) {
      return;
    }

    return this.$api.Companies.searchCompanies(this.ui.companyName)
      .then(data => this.companies = data.companies);
  }

  debouncedSearchCompanies = debounce(this.searchCompanies, 300);

  getDomain = () => {
    if (!this.email) {
      return;
    }

    return this.email.split('@')[1];
  }

  getCompanies = () => {
    const domain = this.getDomain();

    if (!domain) {
      return;
    }

    return this.$api.Companies.getByDomain(domain)
      .then((data) => {
        this.companies = data.companies;
      });
  }

  debouncedGetCompanies = debounce(this.getCompanies, 300);

  findExactMatch = () => {
    if (!this.companies || !this.ui.companyName) {
      return;
    }
    return this.companies.find(company => company.name.toLowerCase().trim() === this.ui.companyName.toLowerCase().trim());
  }

  allowCreateCompany = () => {
    return this.ui.companyName && !this.findExactMatch();
  }

  handleBlur = ($event) => {
    this.$timeout(() => {
      const company = this.findExactMatch();
      if (company) {
        this.select(company);
      } else {
        this.createCompany();
      }
      this.ui.editingCompany = false;
    });
  }

  startEditing = () => {
    this.user.company = null;
    this.ui.editingCompany = true;
  }

  displayCompanyList = () => {
    if (!this.user) {
      return;
    }

    const hasTypedTwoCharacters = this.ui.companyName && this.ui.companyName.length > 1;
    const hasCompaniesInList = this.companies && this.companies.length;

    return hasCompaniesInList && hasTypedTwoCharacters && (!this.user.company || this.ui.editingCompany) && !this.ui.creatingCompany;
  }

  displaySelectedCompany = () => {
    if (!this.user) {
      return;
    }
    return this.user.company && !this.ui.editingCompany && !this.ui.creatingCompany;
  }

  displayNewCompanyForm = () => {
    return this.ui.creatingCompany;
  }

}


export const selectCompanyComponent = {
  controller: SelectCompanyController,
  template: require('./select-company.component.jade'),
  bindings: {
    email: '<',
    user: '<',
    selectCompany: '<',
    placeholder: '<',
    tabIndex: '@'
  }
};
