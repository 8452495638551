import { isActualUser } from 'common/dist/virtuals/User';
import { RawBaseUser } from 'spc/lib/database/types/base-user';
import { RawLead } from 'spc/lib/database/types/lead';

export const obscuredLoginModal = ['ngDialog', function(ngDialog) {
  return ({ primaryUser, lead, premiumRegistrationRequest, allowClose, eventPageRequest = false }: {primaryUser: RawBaseUser, lead?: RawLead, allowClose: boolean, premiumRegistrationRequest: boolean, eventPageRequest: boolean }) => {
    return ngDialog.open({
      template:
      `<obscured-login premium-Registration-Request="$ctrl.premiumRegistrationRequest" primary-user="$ctrl.primaryUser" lead="$ctrl.lead" close="$ctrl.close({ user, isNewUser })" event-page-request="$ctrl.eventPageRequest"></obscured-login>`,
      className: 'ngdialog-theme-small ol-modal',
      overlay: true,
      plain: true,
      controllerAs: '$ctrl',
      showClose: false,
      closeByDocument: allowClose,
      closeByNavigation: true,
      controller: ['$scope', function($scope) {
        this.primaryUser = primaryUser;
        this.premiumRegistrationRequest = premiumRegistrationRequest;
        this.eventPageRequest = eventPageRequest;
        this.lead = lead;
        this.close = function({ user, forceClose, isNewUser }: { user?: RawBaseUser, forceClose?: boolean, isNewUser?: boolean }) {
          if (!forceClose && !user || !isActualUser(user)) {
            return;
          } else {
            $scope.closeThisDialog({ isNewUser });
          }
        };
      }]
    }).closePromise;
  };
}];
