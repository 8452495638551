import _ from 'lodash';
import { get, min, max } from 'lodash';
import spaceAvailability from 'common/dist/spaceAvailability';
import VenueHelpers from 'common/dist/virtuals/Venue';

// Types
import { ResultCardService } from './result-card.service';
import VenueSearchMap from './venue-search-map.service';
import { ReviewsService } from 'spc/reviews/reviews.service';
import { RawVenue } from 'lib/database/types/venue/venue';


/**
 * Presentational component to display space-related information as a search result card
 */
let ctrl;
class SpaceSearchResultCardComponent {
  venue: RawVenue;
  constructor($cloudinary, private resultCardService: ResultCardService, private VenueSearchMapService: VenueSearchMap, private addVenueToListModal, private cspVideoPlayer) {
    'ngInject';
    ctrl = this;
    ctrl.$cloudinary = $cloudinary;
  }

  /**
   * Gets the cloudinary url for a space's cover index photo, and defaults the image type to ipad
   *
   * @public
   * @param {Object} space
   * @param {String} imgType, defaults to `ipad`
   * @return {String}
   */
  fetchAndConvertURL(space, imgType = 'ipad') {
    if (!get(space, 'data.photos.length')) {
      return '';
    }
    return ctrl.$cloudinary.fetchAndConvertURL(space.data.photos[space.data.coverIndex].url, imgType);
  }

  getVenueViewUrl(venue, spaceId) {
    return ctrl.search.getVenueViewUrl(venue, spaceId);
  }

  getVideoPlayer(videos, venueName, venueAddress) {
    if (!videos.length) {
      return;
    }
    const teaserVideo = videos.find(vid => vid.title === 'Teaser' ? vid : null);

    if (_.isEmpty(teaserVideo)) {
      return;
    }
    return ctrl.cspVideoPlayer({ video: teaserVideo, venueAddress: venueAddress, venueName: venueName, venueSlug: this.venue.slug });
  }

  showVideoIcon(venue) {
    const isPremiumUser = ctrl.isPremiumUser;
    return ctrl.resultCardService.showVideoIcon({ venue, isPremiumUser });
  }

  /**
   * Gets the src-set href for a space's cover index photo
   *
   * @public
   * @param {Object} space
   * @return {String}
   */
  srcSet(space) {
    if (!get(space, 'data.photos.length')) {
      return '';
    }
    return ctrl.$cloudinary.srcSet(space.data.photos[space.data.coverIndex].url);
  }

  isBookingPriority = VenueHelpers.isBookingPriority;

  displaySpaceCapacity(space) {

    const seatedMin = space.data.capacity.seated.selected ? space.data.capacity.seated.min : 0;
    const seatedMax = space.data.capacity.seated.selected ? space.data.capacity.seated.max : 0;

    const standingMin = space.data.capacity.standing.selected ? space.data.capacity.standing.min : 0;
    const standingMax = space.data.capacity.standing.selected ? space.data.capacity.standing.max : 0;

    const minVal = min([seatedMin, standingMin]);
    const maxVal = max([seatedMax, standingMax]);

    if (!minVal && !maxVal) {
      return '';
    }

    if (!minVal) {
      return maxVal;
    } else if (!maxVal) {
      return minVal;
    }

    return `${minVal} - ${maxVal}`;
  }

  public showFbMin = (space) => {
    return !!this.getSpaceMin(space);
  }

  public getSpaceMin = (space) => {
    if (get(ctrl, 'search.filters.date') && ctrl.isAdmin) {
      const dateTime = get<any>(ctrl, 'search.filters.date');
      const date = dateTime.format('YYYY-MM-DD');
      const time = parseInt(dateTime.format('HHmm'), 10);
      const index = spaceAvailability.getTimeSlot(space, date, time);
      const slot = space.data.timeSlots[index];
      return get(slot, 'terms.foodBeverageMin');
    } else {
      return;
    }
  }
  saveVenue = () => {
    return this.addVenueToListModal(this.venue);
  }
  handleHover = (venue) => {
    this.VenueSearchMapService.triggerMarker(venue._id);
  }

  handleHoverOut = (venue) => {
    this.VenueSearchMapService.resetIcon(venue._id, 'csp');
  }

  getRating = (venue) => {
    return get(ctrl.search, `ratings.${venue._id.toString()}.venueRecommendation`);
  }

  showRating = (venue) => {
    return get<number>(ctrl.search, `ratings.${venue._id.toString()}.ratingCount`, 0) > 5;
  }

  getCount = (venue) => {
    return get<number>(ctrl.search, `ratings.${venue._id.toString()}.ratingCount`, 0);
  }

}

export default {
  bindToController: true,
  bindings: {
    space: '<',
    venue: '<',
    search: '<',
    isAdmin: '<',
    isPremiumUser: '<'
  },
  template: require('./space-search-result-card.jade'),
  controller: SpaceSearchResultCardComponent
};
