export enum AUTHENTICATION_TABS {
  CHECK_EMAIL = 'CHECK_EMAIL',
  LOG_IN = 'LOG_IN',
  PASSWORD = 'PASSWORD',
  MAGIC_LINK = 'MAGIC_LINK',
  BASE_USER_REGISTRATION = 'BASE_USER_REGISTRATION',
  REGISTER = 'REGISTER',
  MAGIC_LINK_SUCCESS = 'MAGIC_LINK_SUCCESS',
  PASSWORD_SETUP_LINK_SUCCESS = 'PASSWORD_SETUP_LINK_SUCCESS'
}
