import io, { Socket } from 'socket.io-client';
import { DBaseUser } from 'spc/lib/database/types/base-user';
import { DUser } from 'spc/lib/database/types/user';
import { MagicLinkRegistrationData } from 'spc/lib/database/types/magicLink';
import { cloneDeep, get } from 'lodash';
import ENUMS from 'common/dist/enums';

interface WebSocketParams {
  user: DUser | DBaseUser | MagicLinkRegistrationData;
  handleSuccess: (verifiedData: { user: DUser | DBaseUser, token: string }) => void;
}

export class WebSocketService {
  constructor(private unwrapError, private $config) {
    'ngInject';
  }

  /**
   * Initiates a WebSocket connection for real-time user authentication.
   * @param {WebSocketParams} params - Object containing user details and success handler.
   */
  connectAuthWebSocket = ({ user, handleSuccess }: WebSocketParams) => {
    try {
      const userId: string = get(user, '_id', '').toString() || get(user, 'socketUserId', '').toString();

      if (!userId) {
        throw new Error('User ID is required to establish a WebSocket connection.');
      }

      const socket: Socket = this.createWebSocketConnection(userId);

      socket.on('connect', () => {
        this.handleLog(`Connected with socket ID: ${socket.id}`);
      });

      /**
       * Listens for the 'user-verified' event containing authentication details.
       */
      socket.off('user-verified').on('user-verified', (verifiedData) => {
        try {
          const { user: verifiedUser, token, operation } = verifiedData;

          if (!verifiedUser || !token) {
            this.handleLog('Invalid verification data received.');
            return;
          }

          const clonedVerifiedUser = cloneDeep(verifiedUser);
          if (operation === ENUMS.magicLink.OperationType.REGISTER) {
            clonedVerifiedUser._id = verifiedData.socketToken;
          }

          if (userId !== clonedVerifiedUser._id.toString()) {
            this.handleLog('User ID mismatch. Ignoring verification.');
            return;
          }

          handleSuccess(verifiedData);
        } catch (error) {
          this.unwrapError(error);
        }
      });

      /**
       * Disconnects the socket if the maximum number of reconnection attempts is reached.
       */
      socket.on('reconnect_failed', () => {
        this.handleLog('WebSocket reconnection failed after maximum attempts.');
        socket.disconnect();
      });

      // Ensures WebSocket connection is properly closed.
      socket.off('disconnect').on('disconnect', () => {
        if (socket.connected) {
          this.disconnectSocket(socket);
        }
      });
    } catch (error) {
      this.unwrapError(error);
    }
  }

  /**
   * Creates and initializes a WebSocket connection with the given user ID.
   * @param {string} userId - The user's ID.
   * @returns {Socket} - The connected socket instance.
   */
  private createWebSocketConnection = (userId: string): Socket => {
    return io(this.$config.webSocketBasePath.url, {
      path: '/socket.io',
      transports: ['websocket'],
      secure: true,
      reconnectionAttempts: 5,
      query: { userId },
    });
  }

  /**
   * Handles logging in production-ready manner.
   * @param {string} message - Log message.
   */
  private handleLog(message: string): void {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`[WebSocketService]: ${message}`);
    }
  }

  /**
   * Function to disconnects a given WebSocket instance. Ensures all event listeners are removed before closing the connection.
   * @param {Socket} socket - The WebSocket instance to disconnect.
   */
  private disconnectSocket = (socket: Socket) => {
    socket.off('user-verified');
    socket.off('disconnect');
    socket.disconnect();
    this.handleLog(`WebSocket disconnected`);
  }
}
