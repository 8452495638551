import { DAccount } from 'spc/lib/database/types/accounts';
import { DBaseUser, RawBaseUser } from 'spc/lib/database/types/base-user';
import { DUser } from 'spc/lib/database/types/user';
import { DBookingRequest } from 'spc/lib/database/types/booking-request';
import { ApiService } from '../shared/api/api.service';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';

interface Link {
  name: string;
}
class MembershipAccountController {
    ready: () => void;
    account: DAccount;
    bookings: DBookingRequest[];
    members: DBaseUser[];
    links: Link[];
    user: DUser;
    hasAccount: boolean;
    membersLimit: number;
    displayMembers: string;
    isDowngraded: boolean;
    premiumAccountRequested: boolean;
    requestModal: boolean = false;
    isUser: boolean;
    pro: boolean;
    premium: boolean;
    isLoggedIn: boolean;
    accountRequest: {
        successMessage: string;
    } = {
        successMessage: ''
    };

    ui: {
        loading: boolean
    } = {
        loading: false
    };
    isReqForTrailPending: boolean = false;
    sortParameter: {
        key: string,
        order: 1 | -1 | 0,
        value: string
    } = {
        key: '',
        order: 0,
        value: ''
    };
    sortValueMapper = {
        name: 'fullName',
        email: 'profile.email',
        owner: 'owner.fullName'
    };

    constructor(
        private $seo,
        private $api: ApiService,
        private $location,
        private toast,
        $scope,
        private $user,
        private unwrapError,
        private addAccountMemberModal,
        private membersLimitMessageModal,
        private manageAccountMembersModal,
        private subscriptionCardServices
    ) {
    'ngInject';
    $scope.$on('CHANGE_PARENT_STATE', function(event, args) {
        $scope.currentState = args.state;
    });

    $scope.isState = function isState(state) {
        return $scope.currentState === state;
    };
  }

    $onInit = async () => {
        try {
            ({ isDowngraded: this.isDowngraded,
               user: this.user,
               isLoggedIn: this.isLoggedIn,
               pro: this.pro,
               premium: this.premium,
               premiumAccountRequested: this.premiumAccountRequested} = await this.subscriptionCardServices.setUpUserData());
            this.ui.loading = true;
            this.links = [
              { name: 'Members' },
              { name: 'Billing & Plans' },
              { name: 'Assets' }
            ];
            this.getMembershipAccount()
                .finally(() => {
                    this.ui.loading = false;
                });
        }
        catch (error) {
            this.unwrapError(error);
        }
    }

    getMembershipAccount = () => {
        return this.$api.MembershipAccount.getAccount()
            .then((res) => {
                if (!res.account) {
                    this.hasAccount = false;
                } else {
                    this.account = cloneDeep(res.account);
                    this.membersLimit = this.account.membersLimit;
                    this.members = this.account.members as DBaseUser[];
                    this.displayMembers = `${this.account.membersCount}/${this.membersLimit}`;
                    this.bookings = res.bookings;
                    this.hasAccount = true;
                }
                this.$seo.set('Team');
                this.ready();
            })
            .catch((error) => {
                this.ready();
                this.unwrapError(error);
            });
    }

    isAccountAdmin = () => {
        const user = this.$user.$ as RawBaseUser;
        const userId = user._id.toString();
        const accountAdminId = get(this.account, 'admin._id', '');

        if (userId === accountAdminId.toString()) {
            return true;
        }
        return false;
    }

    resendInvitationEmail = (user) => {
        this.$api.MembershipAccount.resendEmail({ accountId: this.account._id.toString(), user })
            .then((res) => {
                if (res.success === true) {
                    this.toast.goodNews('Email sent successfully');
                }
            })
            .catch(error => this.unwrapError(error));
    }

    openAddAccountMember = () => {
        const accountMembersLimit = this.membersLimit - this.account.membersCount;
        const user = this.$user.$ as RawBaseUser;
        if (this.account.accountType !== 'Complimentary') {
            if (this.account.membersCount < this.membersLimit) {
                return this.addAccountMemberModal({ user: user, account: this.account, memberLimit: accountMembersLimit })
                    .then((res) => {
                        if (res.value !== undefined && res.value.account) {
                            this.account = cloneDeep(res.value.account);
                            this.membersLimit = this.account.membersLimit;
                            this.displayMembers = `${this.account.membersCount}/${this.membersLimit}`;
                            this.members = this.account.members as DBaseUser[];
                            this.hasAccount = true;
                        }
                });
            } else if (this.account.membersCount >= 0) {
                return this.membersLimitMessageModal()
                    .then((response) => {
                        if (get(response, 'value.cancel')) {
                        return;
                    }
                    return this.manageAccountMembersModal({ user: user, account: this.account })
                        .then((res) => {
                           if (res.value !== undefined && res.value.account) {
                                this.account = cloneDeep(res.value.account);
                                this.membersLimit = this.account.membersLimit;
                                this.displayMembers = `${this.account.membersCount}/${this.membersLimit}`;
                                this.members = this.account.members as DBaseUser[];
                                this.hasAccount = true;
                            }
                    });
                })
                .catch(error => this.unwrapError(error));
            }
        } else {
            return this.addAccountMemberModal({ user: user, account: this.account, memberLimit: accountMembersLimit })
                .then((res) => {
                    if (res.value !== undefined && res.value.account) {
                    this.account = cloneDeep(res.value.account);
                    this.membersLimit = this.account.membersLimit;
                    this.displayMembers = `${this.account.membersCount}/${this.membersLimit}`;
                    this.members = this.account.members as DBaseUser[];
                    this.hasAccount = true;
                }
            });
        }
    }

    manageAccountMembers = () => {
        const user = this.$user.$ as RawBaseUser;
        return this.manageAccountMembersModal({ user: user, account: this.account })
            .then((res) => {
                if (res.value !== undefined && res.value.account) {
                    this.account = cloneDeep(res.value.account);
                    this.membersLimit = this.account.membersLimit;
                    this.displayMembers = `${this.account.membersCount}/${this.membersLimit}`;
                    this.members = this.account.members as DBaseUser[];
                    this.hasAccount = true;
                }
        });
    }


    getEndDate = ({ createdAt, endDate }) => {
        if (!endDate && endDate !== null) {
            const createdDate = Date.parse(createdAt);
            return moment(new Date(createdDate)).add(30, 'days').format('MMM DD, YYYY');
        } else {
            const date = !endDate ? 'N/A' : moment(endDate).format('MMM DD, YYYY');
            return date;
        }
    }

    findBookingUser = (booking) => {
        return this.members.find(member => booking.clients.find(client => client.isPrimary && client.user === member._id));
    }

    isAdmin = (user) => {
        return user._id === this.account.admin['_id'];
    }

    isBaseUser = (user) => {
        return user.__t ? true : false;
    }

    closeModal = () => {
        this.requestModal = this.subscriptionCardServices.closeModal();
    }

    async requestAccess() {
        this.isReqForTrailPending = true;
        try {
            this.requestModal = await this.subscriptionCardServices.requestAccess();
            this.isReqForTrailPending = false;
        }
        catch (error) {
            this.isReqForTrailPending = false;
            this.unwrapError(error);
        }
    }

    setSortParameter = (param: string) => {
        const sortKey = get(this.sortParameter, 'key');
        const sortOrder = get(this.sortParameter, 'order');
        const sortValue = this.sortValueMapper[param];

        if (sortKey === param) {
            this.sortParameter.order = sortOrder === -1 ? 1 : -1;
            this.sortParameter.value = sortOrder === -1 ? sortValue : `-${sortValue}`;
        } else {
            this.sortParameter.key = param;
            this.sortParameter.order = 1;
            this.sortParameter.value = sortValue;
        }
    }
}

export const membershipAccountComponent = {
    template: require('./membership-account.component.jade'),
    controller: MembershipAccountController,
    bindings: {
        ready: '<',
    },
};
