import JSONCrush from '../../../../../database/helpers/JSONCrush';
import { CONTENT_FILTER } from '../../../../../database/constants/ContentFilter';
import { RATINGS_FILTER, MICHELIN_STARS, PRICE, MAPPED_PRICE, STYLE_FILTER, PRICE_POUND } from '../../../../../database/constants/search-filters';
import { every, remove, reject, some, isObject } from 'lodash';
import _ from 'lodash';

// Constants
const PATHS_TO_DISPLAY_NAME = {
  spaces: {
    'data.privacy': 'Privacy Level',
  },
  venue: {
    'internal.menuPrices': 'Price',
    'admin.ambiance': 'Character',
  },
};

/**
 * Service to handle business logic of applying filters in venue service.search.
 * Most of these functions take the instantiated search object created from
 * activating the VenueSearchService
 */
let service;
export default class VenueSearchFiltersService {
  enabledFilters: { path: string; schema: string }[];

  constructor(
    ENUMS,
    $q,
    $httpParamSerializer,
    $location,
    $routeParams,
    private $rootScope: any,
    $timeout,
    private $user,

  ) {
    'ngInject';
    service = this;
    service.ENUMS = ENUMS;
    service.$q = $q;
    service.selected = {};
    service.$location = $location;
    service.$httpParamSerializer = $httpParamSerializer;
    service.queryParams = {};
    service._locationParams = $routeParams;
    service.$timeout = $timeout;
  }

  /**
   * Activates service by initializing certain state-management values on it. It'simportant to keep track of these
   * so we can clean them up when the component making use of this service is destroyed.
   *
   * @public
   * @param {Object} search, created by activating Search service
   * @return {Object} service
   */
  activate(search) {
    service.queryParams = { ...service._locationParams };
    service.search = search;
    service.filters = service.getFilters(service.search.stateBucket);
    service.privacy = service.getPrivacyFilters(service.search.stateBucket);
    service.price = service.getPriceFilter();
    service.style = service.getStyleFilters(service.search.stateBucket);
    service.enabledFilters = [];
    service.generateEnabledFilters();

    return service;
  }

  /**
   * Clean up function to clear up any state
   *
   * @public
   * @return {Object} service
   */
  clean() {
    service.search = null;
    service.enabledFilters = null;
    service.filters = null;
    return service;
  }

  /**
   * Gets count for a given value for a filter from the initialCounts object
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {String} schema
   * @return {Number}
   */
  getInitialCount(path, value, schema) {
    if (!service.search || !service.search.initialCounts) {
      return;
    }

    const countPath = _getPath(path, schema);

    if (!service.search.initialCounts[countPath]) {
      return;
    }

    return service.search.initialCounts[countPath][value] >= 0;
  }

  /**
   * Gets count for a given value for a filter.
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {String} schema
   * @return {Number}
   */
  getCount(path, value, schema) {
    if (!service.search || !service.search.counts) {
      return;
    }
    const countPath = _getPath(path, schema);
    if (!service.search.counts[countPath]) {
      return;
    }

    if (isObject(value)) {
      const key = Object.keys(value)[0];
      return service.search.counts[countPath][key][value[key]];
    }
    if (countPath === 'data.price') {
      value = this.getMappedValue(value);
    }
    return service.search.counts[countPath][value] || 0;
  }

  getMappedValue(value) {
    return MAPPED_PRICE[value];
  }

  isFilterEnabledArray(path, value, schema) {
    schema = schema || 'venue';
    if (!service.search.filters[schema][path]) {
      return false;
    }
    if (path === 'data.price') {
      value = this.getMappedValue(value);
    }
    return service.search.filters[schema][path].includes(value);
  }

  isNeighborhoodFilterEnabled({ city, neighborhood, schema, path }) {
    const filters = service.search.filters[schema][path];
    if (!filters) {
      return false;
    }
    return filters.some(
      location =>
        location.city === city && location.neighborhood === neighborhood
    );
  }

  isCityFilterEnabled(city) {
    return !!service.search.selectAll[city];
  }

  /**
   * Clear all filters and redraws markers on map
   *
   * @public
   * @return {Promise}
   */

  clearAllFilters() {
    service.enabledFilters = [];
    return service.search.reset();
  }

  /**
   * Determines if search counts have any venues for the given bucket.
   * For instance, if, after a query, there are no current venues with any Venue Amenities,
   * this will return false for that path
   */
  bucketHasCount(path, schema) {
    const buckets = service.search.counts;

    if (schema === '_amenities') {
      const amenities = buckets['data.amenities.item'];
      const amenitiesValues = amenities ? Object.values(amenities) : [];

      if (!amenitiesValues.length || !every(amenitiesValues)) {
        return false;
      }
      return true;
    }
    const countPath = _getPath(path, schema);

    return some(buckets[countPath], (value) => {
      if (isObject(value)) {
        return true;
      }
      return value > 0;
    });
  }

  /**
   * pushes filters to `$scope.enabledFilters` and to relevant search bucket, then applies filters
   *
   * @public
   * @param {String} path
   * @param {Any} value
   * @param {Schema} schema
   * @param {Boolean} toPush - whteher or not to push
   * @return {Promise}
   */
  toggleFilterArray(path, value, schema, toPush) {
    const count = service.getCount(path, value, schema);
    if (path === 'data.price') {
      value = this.getMappedValue(value);
    }
    if (count || path === 'admin.network') {
      service.adjustFiltersOnToggle(path, value, schema, toPush);

      const pathsToIgnore = determinePathsToIgnore(path, schema);

      service.search.page = 0;
      return service.search.applyFilters(pathsToIgnore);
    }
  }

  generateEnabledFilters() {
    let filters;
    if (service._locationParams.filters) {
      filters = JSON.parse(JSONCrush.uncrush(service._locationParams.filters)).filters;
    }

    if (filters) {
      for (const key in filters) {
        if (filters[key]['data.address.neighborhood']) {
          service.enabledFilters.push({
            path: 'data.address.neighborhood',
            name: 'Neighborhood',
            schema: key,
          });
        }
        if (filters[key]['data.cuisineTypes']) {
          service.enabledFilters.push({
            path: 'data.cuisineTypes',
            name: 'Cuisine',
            schema: key,
          });
        }
        if (filters[key]['data.privacy']) {
          service.enabledFilters.push({
            path: 'data.privacy',
            name: 'Privacy',
            schema: key,
          });
        }
        if (filters[key]['data.type']) {
          service.enabledFilters.push({
            path: 'menus.data.type',
            name: 'Menu Type',
            schema: key,
          });
        }
        if (filters[key]['data.priceInCents']) {
          service.enabledFilters.push({
            path: 'menus.data.priceInCents',
            name: 'Menu Price',
            schema: key,
          });
        }
        if (filters[key]['data.kind']) {
          service.enabledFilters.push({
            path: 'data.kind',
            name: 'Venue',
            schema: key,
          });
        }
        if (filters[key]['data.price']) {
          service.enabledFilters.push({
            path: 'data.price',
            name: 'Venue',
            schema: key,
          });
        }
        if (filters[key]['data.spaceType']) {
          service.enabledFilters.push({
            path: 'data.spaceType',
            name: 'Space Type',
            schema: key,
          });
        }
        if (filters[key]['data.amenities.item']) {
          service.enabledFilters.push({
            path: 'data.amenities.item',
            name: 'Venue Amenities',
            schema: key,
          });
        }
        if (filters[key]['admin.ambiance']) {
          service.enabledFilters.push({
            path: 'admin.ambiance',
            name: 'Character',
            schema: key,
          });
        }
        if (filters[key]['data.capacity']) {
          service.enabledFilters.push({
            path: 'data.capacity',
            name: 'Style',
            schema: key,
          });
        }
      }
    }
  }
  toggleFullVenue({ path, value, schema, toPush }) {
    const count = service.getCount(path, value, schema);
    if (count) {
      service.adjustFiltersOnToggle(path, value, schema, toPush);

      service.search.page = 0;
      return service.search.applyFilters();
    }
  }

  toggleNeighborhoodFilter({ city, neighborhood, path, schema }) {
    if (!service.search.filters[schema][path]) {
      service.enabledFilters.push({
        path,
        name: 'Neighborhood',
        schema,
      });
    }

    service.search.filters[schema][path] = service.search.filters[schema][path] || [];

    const bucket = service.search.filters[schema][path];

    const index = bucket.findIndex(
      location =>
        location.city === city && location.neighborhood === neighborhood
    );
    const neighborhoods = bucket.map((e) => {
      e.neighborhood;
    });
    if (index === -1) {
      bucket.push({ city, neighborhood });
    } else {
      bucket.splice(index, 1);
    }

    service.selected[city] = bucket.filter(b => b.city === city).length;
    if (!bucket.length) {
      delete service.search.filters[schema][path];
      service.search.selectAll[city] = false;
      remove(
        service.enabledFilters,
        (filter: { path: string; schema: string }) =>
          filter.path === path && (filter.schema || 'venue') === schema
      );
    }

    const pathsToIgnore = determinePathsToIgnore(path, schema);

    service.search.page = 0;
    return service.search.applyFilters(pathsToIgnore);
  }

  selectParentCity({ city, neighborhoods, path, schema }) {
    if (!service.search.filters[schema][path]) {
      service.enabledFilters.push({
        path,
        name: 'Neighborhood',
        schema,
      });
    }

    service.selected[city] = Object.keys(neighborhoods).length;

    service.search.filters[schema][path] = service.search.filters[schema][path] || [];

    const selection = service.search.filters[schema][path];

    const selectedNeighborhoods = selection.map(s => s.neighborhood);
    service.search.selectAll[city] = true;

    const allNeighborhoods = Object.keys(neighborhoods);
    service.selected[city] = allNeighborhoods.length;

    allNeighborhoods.forEach((neighborhood) => {
      if (!selectedNeighborhoods.includes(neighborhood)) {
        selection.push({ city, neighborhood });
      }
    });
    const pathsToIgnore = determinePathsToIgnore(path, schema);
    service.search.page = 0;
    return service.search.applyFilters(pathsToIgnore);
  }

  deselectParentCity({ city, path, schema }) {
    service.selected[city] = 0;
    service.search.filters[schema][path] = service.search.filters[schema][path] || [];
    const selection = service.search.filters[schema][path];

    service.search.filters[schema][path] = selection.filter(
      searchObj => searchObj.city !== city
    );
    const pathsToIgnore = determinePathsToIgnore(path, schema);

    service.search.page = 0;
    return service.search.applyFilters(pathsToIgnore);
  }

  canSelectAll({ city, neighborhoods }) {
    const numberOfNeighborhoods = Object.keys(neighborhoods).length;
    return !service.selected[city] || numberOfNeighborhoods > service.selected[city];
  }

  canDeselectAll(city) {
    return service.selected[city];
  }

  /**
   * Gets filters for a given state bucket
   *
   * @public
   * @param {String} stateBucket
   * @return {Array}
   */
  getFilters(stateBucket) {
    return _getFilters(service.ENUMS, stateBucket);
  }

  getPrivacyFilters(stateBucket) {
    return _getPrivacyFilters(service.ENUMS, stateBucket);
  }

  getStyleFilters(stateBucket) {
    return _getStyleFilters(service.ENUMS, stateBucket);
  }

  getPriceFilter() {
    return service.search.display === 'London' ?  _getPriceFilter(PRICE_POUND) : _getPriceFilter(PRICE);
  }

  /**
   * Clears a given filter from the array of enabled filters
   */
  clearFilter(path, schema) {
    schema = schema || 'venue';
    delete service.search.filters[schema][path];
    service.enabledFilters = reject(
      service.enabledFilters,
      (filter: { path: string; schema: string }) =>
        filter.path === path && (filter.schema || 'venue') === schema
    );

    const pathsToIgnore = determinePathsToIgnore(path, schema);

    service.search.page = 0;
    return service.search.applyFilters(pathsToIgnore);
  }
  // Private functions

  /**
   * Helper fn to adjust filters as user toggles
   *
   * @private
   * @param {String} path (already adjusted)
   * @param {Any} value
   * @param {String} schema
   * @param {Boolean} toPush
   * @return {Void}
   */
  adjustFiltersOnToggle(path, value, schema = 'venue', toPush) {
    if (!service.search.filters[schema][path]) {
      service.enabledFilters.push(
        toPush || {
          path,
          name: PATHS_TO_DISPLAY_NAME[schema][path],
          schema,
        }
      );
    }

    service.search.filters[schema][path] = service.search.filters[schema][path] || [];
    const bucket = service.search.filters[schema][path];
    return service.handleFilterToggle({ bucket, value, schema, path });
  }

  handleFilterToggle({ bucket, value, schema, path }) {
    const idx = bucket.indexOf(value);
    if (idx === -1) {
      bucket.push(value);
    } else {
      bucket.splice(idx, 1);
    }

    if (!bucket.length) {
      delete service.search.filters[schema][path];
      remove(
        service.enabledFilters,
        (filter: { path: string; schema: string }) =>
          filter.path === path && (filter.schema || 'venue') === schema
      );
    }
  }

  getNeighborhoods = () => {
    if (!service.search || !service.search.counts) {
      return;
    }

    const countPath = _getPath('data.address.neighborhood', 'venue');

    if (!service.search.counts[countPath]) {
      return;
    }

    return service.search.counts[countPath] || 0;
  }

  isDinovaOrAdmin() {
    const userEmail = _.get(this, '$user.$.profile.email');
    if (this.$user.isLoggedIn() && userEmail) {
      return userEmail.includes('@dinova.com') || this.$user.isAdmin();
    }

    return false;
  }

}

function determinePathsToIgnore(path: string, schema: string) {
  let ignoreUntilIndex = service.enabledFilters.findIndex((filter) => {
    return filter.path === path && filter.schema === schema;
  });

  if (ignoreUntilIndex === -1) {
    ignoreUntilIndex = service.enabledFilters.length - 1;
  }

  return service.enabledFilters
    .slice(0, ignoreUntilIndex + 1)
    .map(filter => _getPath(filter.path, filter.schema));
}

function _getPath(path, schema) {
  return schema === 'spaces' ? `data.spaces.${path}` : path;
}

/**
 * Returns an array of filters, each of which is represented by a bucket
 * with a set of values that map to a certain path and schema
 *
 * @private
 * @param {Object} ENUMS
 * @param {String} stateBucket
 * @return {Array}
 */
function _getFilters(ENUMS, stateBucket) {
  return [
    {
      name: 'Content',
      types: CONTENT_FILTER,
      path: 'content',
      schema: 'venue'
    },
    {
      name: 'Reviews',
      types: RATINGS_FILTER,
      path: 'data.ratingAndReviews',
      schema: 'venue'
    },
    {
      name: 'Michelin Stars',
      types: MICHELIN_STARS,
      path: 'data.accolades',
      schema: 'venue'
    },
    {
      name: 'Neighborhood',
      path: 'data.address.neighborhood',
      schema: 'venue',
    },
    {
      name: 'Cuisine',
      types: ENUMS.cuisineTypes,
      path: 'data.cuisineTypes',
      schema: 'venue',
    },
    {
      name: 'Menu Type',
      types: ENUMS.menuTypes,
      path: 'menus.data.type',
      schema: 'menus',
    },
    {
      name: 'Menu Price',
      types: ENUMS.searchPriceRanges,
      path: 'menus.data.priceInCents',
      schema: 'menus',
    },
    {
      name: 'Venue',
      types: ENUMS.venueTypes,
      path: 'data.kind',
      schema: 'venue',
    },
    {
      name: 'Space Type',
      types: ENUMS.spaceTypes,
      path: 'data.spaceType',
      schema: 'spaces',
    },
    {
      name: 'Network',
      types: ENUMS.venueAdmin.network,
      path: 'admin.network',
      schema: 'venue'
    },
    {
      name: 'Amenities',
      kinds: [
        {
          name: 'Venue Amenities',
          types: ENUMS.venueAmenities,
          path: 'data.amenities.item',
          schema: 'venue',
        },
        {
          name: 'Space Amenities',
          types: ENUMS.spaceAmenities,
          path: 'data.amenities',
          schema: 'spaces',
        },
      ],
      path: '_amenities',
      schema: '_amenities',
    },
    {
      name: 'Character',
      types: ENUMS.ambiance,
      path: 'admin.ambiance',
      schema: 'venue',
    }
  ];
}

function _getPrivacyFilters(ENUMS, stateBucket) {
  return {
      name: 'Privacy',
      types: ENUMS.spacePrivacy,
      path: 'data.privacy',
      schema: 'spaces',
    };
}

function _getStyleFilters(ENUMS, stateBucket) {
  return {
      name: 'Style',
      types: STYLE_FILTER,
      path: 'data.capacity',
      schema: 'spaces',
    };
}

function _getPriceFilter(PRICE_ENUMS) {
  return {
    name: 'Price',
    types: PRICE_ENUMS,
    path: 'data.price',
    schema: 'venue'
  };
}
