import * as angular from 'angular';

const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-73123304-1';
const GOOGLE_ANALYTICS_4_TRACKING_ID = 'G-3ZLCVTLDLL';
const HEAP_TRACKING_ID = '666173412';
const ZOPIM_KEY = '3jLaP1K1J2eIRZmRQY0efcCLyxDkhylh';
const MIXPANEL_TRACKING_ID = 'bf3bd0682389eb7a9feda8ca05e5aa45';
const VWO_TRACKING_ID = '270072';

console.log('Using prod config');

const m = angular.module('sixplus.config', []);

m.factory('$exceptionHandler', function($injector) {
  return function(exception, reason) {
    exception.message += ' (caused by "' + reason + '")';
    console.error(exception.message);
  };
});

m.factory('$log', function($injector) {
  return {
    log: console.log,
    info: console.info,
    warn: function(message) {
      console.warn(message);
    },
    error: function(message) {
      console.error(message);
    }
  };
});

angular.module('sixplus.config').factory('$config', function() {
  return {
    facebook: {
      appId: '381027528687512'
    },
    googleAnalytics: {
      trackingId: GOOGLE_ANALYTICS_TRACKING_ID
    },
    googleMaps: {
      apiKey: 'AIzaSyBx3SR712DdJDFUdrMePxgqBxc6OI0KPWo'
    },
    heap: {
      trackingId: HEAP_TRACKING_ID
    },
    linkedin: {
      clientId: '77rgr7f2kyfwuf'
    },
    mixpanel: {
      trackingId: MIXPANEL_TRACKING_ID
    },
    zopim: {
      key: ZOPIM_KEY
    },
    vwo: {
      accountId: VWO_TRACKING_ID
    },
    fullstory: { org: '4JEN4' },
    pardot: {
      piAId: '710893',
      piCId: '1806',
      piHostname: 'pi.pardot.com',
      userFormHandler: 'https://go.pardot.com/l/709893/2019-03-12/zv1',
      emailFormHandler: 'http://go.pardot.com/l/709893/2020-05-07/27v8nf',
    },
    googleAnalytics4TrackingId: {
      trackingId: GOOGLE_ANALYTICS_4_TRACKING_ID
    },
    basePath: {
      url: 'https://api.sixplus.com'
    },
    webSocketBasePath: {
      url: 'https://api.sixplus.com'
    }
  };
});

module.exports = 'sixplus.config';
