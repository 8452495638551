import _ from 'lodash';
import { each, map } from 'lodash';
import { search } from 'superagent';

// Constants
const HIGHLIGHT_CLASS = 'highlight-background'; // class to decorate selected info window
const DEFAULT_VIEW_TYPE = 'VENUE';
const DEFAULT_STYLES = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  }
];

const RED_ICON = '//s3.amazonaws.com/sixplus-assets/images/map-marker-luxury-18.png';
const BLACK_ICON = '//s3.amazonaws.com/sixplus-assets/images/map-marker-black-18.png';
const PINK_ICON = '//s3.amazonaws.com/sixplus-assets/images/map-marker.png';
/**
 * STATEFUL service that stores a reference to a google map that can be accessed
 * between components to redraw markers and hook into events when info windows are clicked
 */
let service;
export default class VenueSearchMap {
  constructor($window, private $timeout) {
    'ngInject';
    service = this;
    service.$window = $window;
    service.google = service.$window.google;
    service.markers = [];
    service.isZoomed = false;
    service.isDragged = false;
  }

  /**
   * Initialize map and store on service
   *
   * @public
   * @param {HTMLElement} element
   * @param {Object} config
   * @return {Void}
   */
  init(element, config, viewType = DEFAULT_VIEW_TYPE, scrollTrigger: boolean) {
    service.map = new service.google.maps.Map(element, config);
    service.map.setOptions({ styles: DEFAULT_STYLES });
    service.viewType = viewType;
    service.scrollTrigger = scrollTrigger;
  }

  /**
   * Optional clean up to be executed when the top-level component is destroyed
   *
   * @public
   * @return {Void}
   */
  clean() {
    service.map = null;
    service.markers = [];
    service.infoWindow = null;
    service.viewType = DEFAULT_VIEW_TYPE;
  }

  setViewType(viewType) {
    service.viewType = viewType;
  }
  /**
   * Draws markers on the map that lives on the service from a given array of venues
   *
   * @public
   * @param {Array} venues
   * @return {Void}
   */
  drawMarkers(venues, lead?) {
    each(service.markers, m => m.setMap(null));
    service.markers = [];

    if (!service.map) {
      return;
    }

    const bounds = new service.google.maps.LatLngBounds();
    const markerIcon = lead ? PINK_ICON : BLACK_ICON;

    service.markers = map(venues, (venue: { _id: string; data: any }) => {
      const point = new service.google.maps.LatLng(venue.data.address.coordinates[1], venue.data.address.coordinates[0]);
      const marker = new service.google.maps.Marker({
        _id: venue._id,
        map: service.map,
        position: point,
        icon: markerIcon
      });

      bounds.extend(marker.position);
      if (lead) {
        service.drawPoiMarker(lead, bounds);
      }

      service.google.maps.event.addListener(service.map, 'click', function() {
        service.infoWindow.close();
      });

      service.google.maps.event.addListener(marker, 'click', (ev) => {
        service.setInfoWindow(venue, marker);
        if (service.scrollTrigger) {
          const venueElement = document.getElementById(`${venue._id}`);
          venueElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          Array.from(venueElement.children).forEach((childNode) => {
            childNode.classList.add('highlight-space-card');
            return this.$timeout(() => {
              childNode.classList.remove('highlight-space-card');
            }, 5000);
          });
        }
      });

      service.google.maps.event.addListener(marker, 'card_hover', (ev) => {
        service.setInfoWindow(venue, marker);
      });

      service.google.maps.event.addListener(marker, 'mouseover', function() {
        service.markers.forEach(_marker => _marker.setIcon(markerIcon));
        marker.setIcon(lead ? PINK_ICON : RED_ICON);
      });

      service.google.maps.event.addListener(marker, 'mouseout', function() {
        marker.setIcon(markerIcon);
      });

      service.google.maps.event.addListener(marker, 'card_hover', (ev) => {
        service.markers.forEach(_marker => _marker.setIcon(markerIcon));
        marker.setIcon(lead ? PINK_ICON : RED_ICON);
      });

      return marker;
    });

    if (!venues.length) {
      return this.$timeout(() => {
        service.map.setZoom(service.map.getZoom());
        service.map.setCenter(service.map.getCenter());
      });
    } else if (venues.length === 1) {
      service.map.setCenter({ lat: venues[0].data.address.coordinates[1], lng: venues[0].data.address.coordinates[0] });
      service.map.setZoom(16);
    }

    if (!service.isDragged && !service.isZoomed) {
      service.map.fitBounds(bounds);
    }
    service.isDragged = false;
    service.isZoomed = false;
  }

  /**
   * Sets html content for info window and opens `scope.infoWindow`
   */
  setInfoWindow(venue, marker) {
    if (service.infoWindow) {
      service.infoWindow.close();
    }
    const contentString = service.generateInfoWindowHTML(venue);
    service.infoWindow = new service.google.maps.InfoWindow({ content: contentString[0].outerHTML });
    service.infoWindow.open(service.map, marker);
  }

  /**
   * Generates the html for the info window for a doc
   *
   * @private
   * @param {Venue} venue
   * @return {HTMLElement}
   */
  generateInfoWindowHTML(venue) {
    let newContainer;
    if (service.viewType === 'VENUE') {
      const originalContainer = $('#' + venue._id);
      newContainer = originalContainer.clone();
      newContainer.attr('id', 'map-card-' + venue._id);
      newContainer.addClass('map-card');
      newContainer.removeClass(`search-result ${HIGHLIGHT_CLASS}`);
    } else if (service.viewType === 'SPACE') {
      newContainer = $(`#${venue._id} .space-info-window`).clone();
      newContainer.attr('id', 'map-card-' + venue._id);
      newContainer.addClass('map-card');
      newContainer.removeClass(`search-result ${HIGHLIGHT_CLASS} ng-hide`);
    }
    return newContainer;
  }

  public addBoundsChangeListener (changeHandler: ({ bottomLeft, upperRight, isMapDraggedOrZoomed }: { bottomLeft: any, upperRight: any, isMapDraggedOrZoomed: boolean }) => any) {
    service.google.maps.event.addListener(service.map, 'dragend', () => {
      service.isDragged = true;
      const bounds = service.map.getBounds();
      const bottomLeft = bounds.getSouthWest();
      const upperRight = bounds.getNorthEast();
      changeHandler({
        bottomLeft: [bottomLeft.lng(), bottomLeft.lat()],
        upperRight: [upperRight.lng(), upperRight.lat()],
        isMapDraggedOrZoomed: true
      });
    });

    service.google.maps.event.addListener(service.map, 'zoom_changed', () => {
      if (service.isZoomed) {
        const bounds = service.map.getBounds();
        const bottomLeft = bounds.getSouthWest();
        const upperRight = bounds.getNorthEast();
        changeHandler({
          bottomLeft: [bottomLeft.lng(), bottomLeft.lat()],
          upperRight: [upperRight.lng(), upperRight.lat()],
          isMapDraggedOrZoomed: false
        });
      }
      service.isZoomed = true;
    });
  }

  public resetMap = (zoom, center) => {
    if (!service.map) {
      return;
    }
    service.map.setZoom(zoom);
    service.map.setCenter(center);
  }

  public triggerMarker = (id: string) => {
    if (service.markers) {
      const selectedMarker = service.markers.find(marker => marker._id === id);
      service.google.maps.event.trigger(selectedMarker, 'card_hover');
    }
  }

  public resetIcon = (id: string, page: string ) => {
    const markerIcon = page === 'addSpacePage' ? PINK_ICON : BLACK_ICON;
    if (service.markers) {
      const selectedMarker = service.markers.find(marker => marker._id === id);
      selectedMarker && selectedMarker.setIcon(markerIcon);
    }
  }

  public drawPoiMarker(lead, bounds) {
    const location = _.get(lead, 'request.location');
    const coordinates = _.get(lead, 'request.locationDetails.coordinates');
    if (!location || !coordinates) {
      return;
    }
    const [lat, lng] = coordinates;
    const poiLocation = new service.google.maps.LatLng(lat, lng);
    const poiMarker = new service.google.maps.Marker({
      position: poiLocation,
      map: service.map,
      icon: 'http://res.cloudinary.com/dbwijvnzn/image/upload/v1731391539/attachments/black-star-icon_lkvmr1.png',
      title: lead.request.location,
      zIndex: 9999
    });

    const poiInfoWindow = new service.google.maps.InfoWindow({
      content: `
        <div class="reco-map-poi-pin-box">
          <h5>POI(Point of Interest):</h5>
          <h4>${lead.request.location}</h4>
        </div>
      `
    });

    poiMarker.addListener('click', () => {
      poiInfoWindow.open(service.map, poiMarker);
    });
    bounds.extend(poiLocation);
  }

}
