/**
 * NPM modules
 */
import { each, get } from 'lodash';
import * as angular from 'angular';
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import 'flickity-bg-lazyload';
import 'angular-flickity';
import 'angular-route';
import status from 'http-status';
import moment from 'moment';
import mongoose from 'mongoose';

// Polyfills
import './polyfills';

// Custom mongoose
// window.mongoose = require('../vendor/browser-mongoose');
window.mongoose = mongoose;

// SixPlus deps (Modules)
import match from './utils/match';
import SharedModule from './shared/shared.module';
import ComponentsModule from './components/components.module';
import ReceiptsModule from './receipts/receipts.module';
import { RecosModule } from './recos/recos.module';
import enums from 'common/dist/enums';
import AnalyticsService from 'spc/shared/analytics/analytics.service';
import { ReviewsModule } from './reviews/reviews.module';
import { ClientDashboardModule } from './client_dashboard/client-dashboard.module';
import { ListsModule } from './lists/lists.module';
import { HistoryModule } from './history/history.module';
import { VirtualExperienceModule } from './virtual-experience/virtual-experience.module';
import { VirtualSearchModule } from './virtual-experiences/virtual-search.module';
import { MembershipAccountModule } from './membership-account/membership-account.module';
import { PremiumPageModule } from './premium-page/premium-page.module';
import { ClientReceiptsModule } from './client_receipts/client-receipts.module';

/**
 * SixPlus deps.
 */

const photos = require('./photos');
const attachments = require('./attachments');
const directives = require('./directives');
const filters = require('./filters');
const services = require('./services');
const constants = require('./constants');

const core = angular.module('core', [
  SharedModule,
  'directives',
  'filters',
  'services',
  'photos',
  'attachments',
  ComponentsModule,
  'Constants',
  require('./admin'),
  require('./auth'),
  require('./animations'),
  require('./config'),
  require('./requests'),
  ReceiptsModule,
  RecosModule,
  ReviewsModule,
  require('./availability'),
  require('./venue_book'),
  require('./payment'),
  require('./venues'),
  require('./venue_dashboard'),
  ClientDashboardModule,
  ListsModule,
  HistoryModule,
  MembershipAccountModule,
  VirtualExperienceModule,
  VirtualSearchModule,
  PremiumPageModule,
  ClientReceiptsModule
]);

const app = angular.module('app', ['core', 'ngRoute', 'bc.Flickity', 'ngDialog']);

app.config(($routeProvider, $locationProvider: ng.ILocationProvider) => {
  $locationProvider.html5Mode({ enabled: true, requireBase: false });
  $routeProvider
    .otherwise({ redirectTo: '/404' })
    .when('/404', {
      template: require('./404.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, $scope) {
          const title = 'Not Found';
          $seo.set(title);
          $scope.$emit('$viewReady', 'SHOW_FOOTER');
        },
        $inject: ['$seo', '$scope']
      })
    })
    .when('/', {
      template: '<home-index></home-index>'
    })
    .when('/new-york', {
      template: '<city-home-index></city-home-index>'
    })
    .when('/test-graph', {
      template: '<test-graph></test-graph>'
    })
    .when('/venue/register/:id', {
      template: '<venue-user-registration></venue-user-registration>'
    })
    .when('/register/:id', {
      template: '<complete-registration></complete-registration>'
    })
    .when('/register', {
      template: '<registration></registration>',
      controller: ViewLevelController(require('./controllers/Register'))
    })
    .when('/venue-register', {
      template: '<registration registering-venue="true"></registration>',
      controller: ViewLevelController(require('./controllers/Register'))
    })
    .when('/login', {
      template: '<login></login>',
      controller: ViewLevelController(require('./controllers/Login'))
    })
    .when('/reset-password', {
      template: '<reset-password></reset-password>',
      controller: ViewLevelController(require('./controllers/resetPassword'))
    })
    .when('/auth/verify', {
      template: '<magic-link-verification></magic-link-verification>',
      controller: ViewLevelController(require('./controllers/magicLink'))
    })
    .when('/reset-password/:uuid', {
      template: '<reset-password></reset-password>',
      controller: ViewLevelController(require('./controllers/resetPassword')),
      resolve: {
        uuid: function ($api, $route) {
          /**
           * put in resolve just for the side effect of intercepting
           * the 400 error before the user can see if the page
           * would have loaded or not
          */

          return $api.Auth.getUUID($route.current.params.uuid)
            .then(response => response.data.uuid);
        }
      }
    })
    .when('/unsubscribe-newsletter', {
      template: '<unsubscribe-newsletter></unsubscribe-newsletter>',
      controller: ViewLevelController({
        $controller: function ($seo) {
          const title = 'Unsubscribe';
          $seo.set(title);
        },
        $inject: ['$seo']
      })
    })
    .when('/welcome', {
      template: require('./welcome.jade'),
      controller: ViewLevelController(require('./controllers/Welcome'))
    })
    .when('/about', {
      template: require('./about.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS, $scope) {
          const title = 'Who We Are';
          const description = _ENUMS.metaDescriptions.about;
          $seo.set(title, description);
          $scope.$emit('$viewReady', 'SHOW_FOOTER');
        },
        $inject: ['$seo', 'ENUMS', '$scope']
      })
    })
    .when('/pricing', {
      redirectTo: function () {
        return `/upgrade`;
      }
    })
    .when('/referrals', {
      template: require('./referrals.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS, $scope) {
          const title = 'Get Rewards';
          const description = _ENUMS.metaDescriptions.about;
          $seo.set(title, description);
          $scope.$emit('$viewReady', 'SHOW_FOOTER');
        },
        $inject: ['$seo', 'ENUMS', '$scope']
      })
    })
    .when('/review/event/:conversation', {
      template: '<submit-review></submit-review>'
    })
    .when('/faq', {
      template: require('./faq.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS, $scope) {
          const title = 'FAQ';
          const description = _ENUMS.metaDescriptions.faq;
          $seo.set(title, description);
          $scope.$emit('$viewReady', 'SHOW_FOOTER');
        },
        $inject: ['$seo', 'ENUMS', '$scope']
      })
    })
    .when('/terms', {
      template: require('./terms.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS) {
          const title = 'Terms';
          const description = _ENUMS.metaDescriptions.terms;
          $seo.set(title, description);
        },
        $inject: ['$seo', 'ENUMS']
      })
    })
    .when('/privacy', {
      template: require('./privacy.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS) {
          const title = 'Privacy';
          const description = _ENUMS.metaDescriptions.privacy;
          $seo.set(title, description);
        },
        $inject: ['$seo', 'ENUMS']
      })
    })
    .when('/goodbye', {
      template: require('./goodbye.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS) {
          const title = 'Goodbye BookaLokal';
          $seo.set(title);
        },
        $inject: ['$seo', 'ENUMS']
      })
    })
    .when('/concierge', {
      template: '<top-level-concierge-form></top-level-concierge-form>'
    })
    .when('/concierge-submitted', {
      template: '<concierge-submitted></concierge-submitted>'
    })
    .when('/request-submitted', {
      template: '<concierge-submitted></concierge-submitted>'
    })
    .when('/get-started', {
      template: require('./get-started.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, $rootScope, $scope) {
          const title = 'Get started with SixPlus for Restaurants';
          const description = 'Want to reach more private dining customers? Tell us about your venue and we\'ll follow up to discuss next steps.';
          $seo.set(title, description);
          $rootScope.$emit('$viewReady', 'HIDE_FOOTER');
          $scope.flickityOptions = {
            wrapAround: true,
            autoPlay: 5000
          };
        },
        $inject: ['$seo', '$rootScope', '$scope']
      })
    })
    .when('/admin/venue', {
      template: '<admin-venues-dashboard></admin-venues-dashboard>',
    })
    .when('/admin/companies', {
      template: `<top-level-admin-companies></top-level-admin-companies>`
    })
    .when('/admin/lead-metrics', {
      template: '<lead-metrics></lead-metrics>'
    })
    .when('/admin/user', {
      template: '<top-level-admin-users></top-level-admin-users>',
    })
    .when('/admin/proposals', {
      template: '<admin-events-dashboard></admin-events-dashboard>'
    })
    .when('/admin/calendar', {
      template: '<top-level-admin-calendar></top-level-admin-calendar>'
    })
    .when('/admin/lead/:id', {
      template: `<top-level-concierge></top-level-concierge>`
    })
    .when('/admin/events', {
      template: '<admin-leads-dashboard></admin-leads-dashboard>',
      controller: ViewLevelController({
        $controller: function ($seo) {
          $seo.set('Events (Admin)');
        },
        $inject: ['$seo']
      })
    })
    .when(`/admin/conversations`, {
      template: `<top-level-admin-conversations-dashboard></top-level-admin-conversations-dashboard>`
    })
    .when('/admin/proposal-metrics', {
      template: '<proposal-metrics></proposal-metrics>'
    })
    .when('/admin/settings', {
      template: '<admin-settings></admin-setting>'
    })
    .when('/admin/settings/cities', {
      template: '<edit-cities></edit-cities>'
    })
    .when(`/events`, {
      template: `<top-level-personal-events-dashboard></top-level-personal-events-dashboard>`
    })
    .when('/user/events', {
      template: `<top-level-concierge-requests-dashboard></top-level-concierge-requests-dashboard>`,
      controller: ViewLevelController({
        $controller: function ($seo) {
          $seo.set('Events Dashboard');
        },
        $inject: ['$seo']
      })
    })
    .when('/user/dashboard', {
      template: '<client-dashboard></client-dashboard>'
    })
    .when('/user/receipts', {
      template: `<client-receipts></client-receipts>`,
    })
    .when('/user/reviews', {
      template: `<user-reviews></user-reviews>`,
    })
    .when('/venue/reviews', {
      template: `<venue-reviews-dashboard></venue-reviews-dashboard>`
    })
    .when('/admin/vendors', {
      template: '<top-level-admin-vendors></top-level-admin-vendors>'
    })
    .when('/admin/vendors/create', {
      template: '<top-level-admin-create-edit-vendors></top-level-admin-create-edit-vendors>'
    })
    .when('/admin/vendors/:id/edit', {
      template: '<top-level-admin-create-edit-vendors></top-level-admin-create-edit-vendors>'
    })
    // We disabled virtual experinence component temporiorily
    /*
    .when('/admin/vendors/:vendorId/experience/create', {
      template: '<top-level-admin-create-edit-experience></top-level-admin-create-edit-experience>'
    })
    .when('/admin/vendors/:vendorId/experience/:id/edit', {
      template: '<top-level-admin-create-edit-experience></top-level-admin-create-edit-experience>'
    })
    .when('/admin/experiences', {
      template: '<top-level-admin-experiences></top-level-admin-experiences>'
    })
    .when('/experience/:experienceId', {
      template: '<top-level-virtual-experience></top-level-virtual-experience>'
    })
    .when('/experiences', {
      template: '<top-level-virtual-search></top-level-virtual-search>'
    })
    */
    .when('/admin/team/create', {
      template: '<top-level-admin-create-edit-account></top-level-admin-create-edit-account>'
    })
    .when('/admin/team/:id/edit', {
      template: '<top-level-admin-create-edit-account></top-level-admin-create-edit-account>'
    })
    .when('/admin/teams', {
      template: '<top-level-admin-accounts></top-level-admin-accounts>'
    })
    .when('/team', {
      template: '<top-level-membership-account></top-level-membership-account>'
    })

    .when('/upgrade', {
      template: '<top-level-premium-page></top-level-premium-page>',
    })
    .when('/premium', {
      redirectTo: function () {
        return `/upgrade`;
      }
    })
    .when('/account', {
      redirectTo: function () {
        return `/team`;
      }
    })


    /**
     * Reload on search is a property read by $ngRouteProvider that tells Angular
     * not to refresh the page when the url path changes. This is necessary because
     * we modify query strings to paginate the search, which in turn is necessary
     * for SEO purposes
     */
    .when('/search/:name', {
      template: '<venue-search-control></venue-search-control>',
      reloadOnSearch: false
    })
    .when('/user/account', {
      template: '<user-account></user-account>',
      controller: ViewLevelController(require('./controllers/UserAccount'))
    })
    .when('/user/lists', {
      template: '<top-level-lists></top-level-lists>',
    })
    .when('/user/lists/:id', {
      template: '<individual-list></individual-list>',
    })
    .when('/user/calendar', {
      template: '<top-level-availability-calendar></top-level-availability-calendar>'
    })
    .when('/user/venues', {
      template: '<user-venues-index venues="Venues" menus="Menus" drinks="Drinks" user="user"></user-venues-index>',
      controller: ViewLevelController(require('./venue_dashboard/controllers/UserVenuesIndex'))
    })
    .when('/user/venues/:slug/', {
      // Use the `user-venue-new` until we create it.
      template: '<user-venue-new record="venue"></user-venue-new>',
      controller: ViewLevelController({
        $controller: function ($scope, Venue, $seo) {
          const title = 'Edit Venue';
          $seo.set(title);
          $scope.venue = Venue.data.data.venue;
        },
        $inject: ['$scope', 'Venue', '$seo']
      }),
      resolve: {
        Venue: function ($route, $api, unwrapError) {
          return $api.Venues.get($route.current.params.slug, { getNonVisible: true, authorizedUsers: true })
            .catch(error => unwrapError(error));
        }
      }
    })
    .when('/user/venues/:slug/photos', {
      template: require('./user-venues-photos.jade'),
      controller: ViewLevelController(require('./venue_dashboard/controllers/UserVenuesPhotos')),
      resolve: {
        Record: function ($route, $api) {
          return $api.Venues.get($route.current.params.slug, { getNonVisible: true });
        },
        Model: function ($api) {
          return $api.Venues;
        }
      }
    })
    .when('/user/venues/:slug/:status/spaces/new', {
      template: '',
      controller: ViewLevelController({
        $controller: function ($location, $routeParams, $api, unwrapError) {
          $api.Venues.Spaces.new($routeParams.slug)
            .then((Space) => {
              $location.path(`/user/venues/${$routeParams.slug}/${$routeParams.status}/spaces/${Space.data.data._id}`);
            })
            .catch(error => unwrapError(error));
        },
        $inject: ['$location', '$routeParams', '$api', 'unwrapError']
      })
    })
    .when('/user/venues/:slug/:status/spaces/:spaceId/', {
      template: '<user-spaces-new record="space"></user-spaces-new>',
      controller: ViewLevelController({
        $controller: function ($scope, Space) {
          $scope.space = Space.data.data;
        },
        $inject: ['$scope', 'Space']
      }),
      resolve: {
        Space: function ($route, $api) {
          return $api.Venues.Spaces.get($route.current.params.slug, $route.current.params.spaceId);
        }
      }
    })
    .when('/user/venues/:slug/spaces/:spaceId/photos', {
      template: require('./user-spaces-photos.jade'),
      controller: ViewLevelController(
        require('./venue_dashboard/controllers/UserSpacesPhotos')),
      resolve: {
        Record: function ($route, $api) {
          return $api.Venues.Spaces.get($route.current.params.slug, $route.current.params.spaceId);
        },
        Model: function ($api) {
          return $api.Venues.Spaces;
        }
      }
    })
    .when('/user/menus/:id/', {
      template: '<user-menus-new record="menu" slug="slug"></user-menus-new>',
      controller: ViewLevelController({
        $controller: function ($scope, Menu) {
          $scope.menu = Menu.data.data;
          $scope.slug = Menu.data.slug;
          $scope.$emit('$viewReady', 'HIDE_FOOTER');
        },
        $inject: ['$scope', 'Menu']
      }),
      resolve: {
        Menu: function ($route, $api) {
          return $api.Menus.get($route.current.params.id, { getNonVisible: true });
        }
      }
    })
    .when('/user/menus/:id/photos', {
      template: require('./user-menus-photos.jade'),
      controller: ViewLevelController(require('./venue_dashboard/controllers/UserMenusPhotos')),
      resolve: {
        Record: function ($route, $api) {
          return $api.Menus.get($route.current.params.id);
        },
        Model: function ($api) {
          return $api.Menus;
        }
      }
    })
    .when('/user/venues/:slug/drinks/new', {
      template: '',
      controller: ViewLevelController({
        $controller: function ($location, $api, $routeParams, unwrapError) {
          $api.Drinks.new({ venue: $routeParams.slug })
            .then((Drink) => {
              $location.path('/user/drinks/' + Drink.data.data._id);
            }).catch(error => unwrapError(error));
        },
        $inject: ['$location', '$api', '$routeParams', 'unwrapError']
      })
    })
    .when('/user/drinks/:id/', {
      template: '<user-drinks-new slug="slug" record="drink"></user-drinks-new>',
      controller: ViewLevelController({
        $controller: function ($scope, Drink) {
          $scope.drink = Drink.data.data;
          $scope.slug = Drink.data.venueSlug;
        },
        $inject: ['$scope', 'Drink']
      }),
      resolve: {
        Drink: function ($route, $api) {
          return $api.Drinks.get($route.current.params.id, { getNonVisible: true });
        }
      }
    })
    .when('/host/:path*', {
      redirectTo: function($routeParams) {
        return `/venue/${$routeParams.path}`;
      }
    })
    .when('/venue/inbox', {
      template: '<top-level-personal-inbox role="venue"></top-level-personal-inbox>'
    })
    .when('/venue/:slug/', {
      template: `<top-level-venue-view></top-level-venue-view>`
    })
    .when('/venue/:slug/book', {
      redirectTo: function($routeParams) {
        return `/venue/${$routeParams.slug}/`;
      }
    })
    .when('/venue/:slug/private/:uuid', {
      template: '<top-level-venue-view private="true"></top-level-venue-view>'
    })
    .when(`/conversation/:id/receipts/`, {
      template: `<top-level-receipts></top-level-receipts>`,
      reloadOnSearch: false
    })
    .when('/conversation/:id/messages', {
      template: `<top-level-conversation-messages></top-level-conversation-messages>`
    })
    .when('/venue/conversation/:id', {
      template: '<venue-conversation></venue-conversation>',
      controller: ViewLevelController({
        $controller: function ($scope, $routeParams, $location) {
          $scope.conversation = $routeParams.id;
          $scope.$on('REDIRECT', (ev, params) => {
            ev.stopPropagation();
            $location.path(params.redirect);
          });
        },
        $inject: ['$scope', '$routeParams', '$location'],
        $waitFor: ['venueConversation']
      })
    })
    .when('/guest/:path*', {
      redirectTo: function($routeParams) {
        return `/client/${$routeParams.path}`;
      }
    })
    .when('/client/inbox', {
      template: '<top-level-personal-inbox role="client"></top-level-personal-inbox>'
    })
    .when('/client/conversation/:id', {
      template: '<guest-conversation conversation="conversation"></guest-conversation>',
      controller: ViewLevelController({
        $controller: function ($scope, $routeParams, $seo, $location) {
          const title = 'Proposal';
          $seo.set(title);

          $scope.conversation = $routeParams.id;

          $scope.$on('REDIRECT', (ev, params) => {
            ev.stopPropagation();
            $location.path(params.redirect);
          });
        },
        $inject: ['$scope', '$routeParams', '$seo', '$location'],
        $waitFor: ['guestConversation']
      })
    })
    .when('/client/conversation/:id/preview/', {
      template: '<top-level-request-preview></top-level-request-preview>'
    })
    .when('/client/conversation/:id/preview/:userId', {
      template: '<top-level-request-preview></top-level-request-preview>'
    })
    .when('/client/conversation/:id/pay', {
      template: '<payment state-ctrl="stateCtrl" conversation="conversation"></payment>',
      controller: ViewLevelController(require('./controllers/Payment'))
    })
    .when('/client/conversation/:id/confirmation', {
      template: '<payment-confirmation conversation="conversation" user="user"></payment-confirmation>',
      controller: ViewLevelController({
        $controller: function ($scope, $routeParams, $user) {
          $scope.conversation = $routeParams.id;
          $scope.user = $user;
          $scope.$emit('$viewReady', 'SHOW_FOOTER');
        },
        $inject: ['$scope', '$routeParams', '$user']
      })
    })
    .when('/recos/:id', {
      template: '<top-level-recos></top-level-recos>'
    })
    .when('/unauthorized', {
      template: require('./unauthorized.jade'),
      controller: ViewLevelController({
        $controller: function ($seo, _ENUMS) {
          const title = 'Unauthorized';
          $seo.set(title);
        },
        $inject: ['$seo', 'ENUMS']
      })
    });
});

core.config(($httpProvider, $provide) => {
  // Custom param serializer because Angular's param serializer does
  // some magic to change the results of encodeURIComponent(), see
  // https://github.com/angular/angular.js/blob/33f7f26558a52124c2eaf40d91bedb58686bde0a/src/Angular.js#L1301
  $httpProvider.defaults.paramSerializer = '$httpParamSerializerJQLike';

  // decorate $document for unit testing for cases where document.referrer is used
  $provide.decorator('$document', ($delegate) => {
    Object.defineProperty($delegate, 'referrer', {
      get: function () {
        return document.referrer;
      }
    });
    return $delegate;
  });
});

core.constant('ENUMS', enums);

core.run(($rootScope) => {
  $rootScope.ENUMS = enums;
  $rootScope.$identity = function (v) { return v; };
});

app.config(($httpProvider) => {
  $httpProvider.interceptors.push(($jwt, $q, $injector) => {
    return {
      request: function (req) {
        // Set the `Authorization` header for every outgoing HTTP request
        if ($jwt.get()) {
          req.headers.Authorization = $jwt.get();
        }
        /**
         * Append the browser type to every request. We had to start doing this because
         * IE and Edge have messed up local storages, so we need to handle caching a bit
         * differently on the server-side
         */
        req.headers['sixplus-browser-type'] = $injector.get('browserDetectorService').browser;

        return req;
      },
      responseError: function (rejection) {
        const $location = $injector.get('$location');
        const $user = $injector.get('$user');
        const loggedIn = !!$user.$;
        const redirectLoginUrl = '/login?redirect=' + encodeURIComponent($location.path());

        if (get(rejection, 'data.tokenExpired')) {
          const theLocation = $location.path();
          $user.logout();
          $location.url(redirectLoginUrl).replace();
        } else if (rejection.status === status.UNAUTHORIZED && !loggedIn) {
          $location.url(redirectLoginUrl).replace();
        } else if ([status.FORBIDDEN, status.NOT_FOUND, status.UNAUTHORIZED].includes(rejection.status)) {
          $location.url('/404').replace();
        }

        return $q.reject(rejection);
      },
      response: function (res) {
        return res;
      }
    };
  });
});

app.run(($rootScope, $validator, $api, $injector, $analytics) => {
  $rootScope.$validator = $validator;
  $analytics.$init($injector);
  const referrerData = $analytics.createReferrerData();
  $analytics.saveReferrerData(referrerData);
});

function ViewLevelController(childController) {
  let args = ['$rootScope'];
  if (childController) {
    args = args.concat(childController.$inject);
  }

  const controller = function ($rootScope) {
    if (childController && childController.$waitFor) {
      let left = childController.$waitFor.length;
      each(childController.$waitFor, (ev) => {
        const deregister = $rootScope.$on(ev, () => {
          deregister();
          --left || $rootScope.$emit('$viewReady');
        });
      });
    } else {
      setTimeout(() => {
        $rootScope.$emit('$viewReady');
      }, 0);
    }

    if (childController && childController.$controller) {
      childController.$controller
        .apply(this, Array.prototype.splice.call(arguments, 1));
    } else if (childController) {
      childController
        .apply(this, Array.prototype.splice.call(arguments, 1));
    }
  };
  controller.$inject = args;

  return controller;
}
