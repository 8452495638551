import _ from 'lodash';
import { get } from 'lodash';
import { UserService } from 'spc/services/user.service';
import { fbMinRangeDollars, roomFeeRange } from 'common/dist/price';

// Types
import VenueSearchMap from './venue-search-map.service';
import { ResultCardService } from './result-card.service';
import { VenueViewUiData } from 'spc/venue-view/venue-view-ui.service';

/**
 * Presentational component to display space-related information as a search result card in shell events
 */
let ctrl;
class SpaceResultForShellCardComponent {
  space;
  venue;
  uiData: VenueViewUiData;
  user: UserService;
  addSpace: (space) => void;
  selectedSpaces;
  calledForSelected;
  spaceAlreadyAdded;
  flickityOptions: any;
  isPremiumUser: boolean;

  constructor($cloudinary, private resultCardService: ResultCardService, private VenueSearchMapService: VenueSearchMap, private photoDialog, private $user: UserService, private cspVideoPlayer, private AvailabilityFactory) {
    'ngInject';
    ctrl = this;
    ctrl.$cloudinary = $cloudinary;
  }

  $onInit = async () => {
    this.uiData = {};
    this.user = this.$user;
    this.flickityOptions = {
      initialIndex: 0,
      wrapAround: true,
      cellAlign: 'left',
      pageDots: false,
    };
  }

  selectSpace () {
    this.space['venue'] = { name: get(this.venue, 'data.name') || get(this.space, 'venue.name'), id: get(this.venue, '_id') || get(this.space, 'venue.id') };
    this.addSpace(this.space);
  }

  showVideoIcon(venue) {
    return ctrl.resultCardService.showVideoIcon({ venue, isPremiumUser: ctrl.isPremiumUser });
  }

  async getVideoPlayer(videos, venueName, venueAddress, venueSlug) {
    return ctrl.resultCardService.getVideoPlayer({ isPremiumUser: ctrl.isPremiumUser, videos, venueName, venueAddress, venueSlug });
  }

  displayMin(space) {
    if (this.venue.status === 'Lite') {
      return 'TBC';
    }
    const fnbMin = fbMinRangeDollars(space);
    return fnbMin === 'Inquire to confirm minimum spend' || fnbMin === 'No minimum spend' ? 'TBC' : fnbMin || 'TBC';
  }

  displayRoomFee (space) {
    if (this.venue.status === 'Lite') {
      return 'TBC';
    }
    return roomFeeRange(space) || 'TBC';
  }

  openPhotoDialog = ({ photos, initialPhoto }): Promise<any> => {
    return this.photoDialog({ photos: photos, initialPhoto: initialPhoto });
  }

  srcSet(space) {
    if (!get(space, 'data.photos.length')) {
      return '';
    }
    return ctrl.$cloudinary.srcSet(space.data.photos[space.data.coverIndex].url);
  }

  handleHover = (venue) => {
    this.VenueSearchMapService.triggerMarker(venue._id);
  }

  handleHoverOut = (venue) => {
    this.VenueSearchMapService.resetIcon(venue._id, 'addSpacePage');
  }
}

export default {
  bindToController: true,
  bindings: {
    space: '<',
    venue: '<',
    calledForSelected: '<',
    spaceAlreadyAdded: '<',
    selectedSpaces: '<',
    addSpace: '&',
    isPremiumUser: '<'
  },
  template: require('./space-result-for-shell-card.jade'),
  controller: SpaceResultForShellCardComponent
};
